import React, { useContext, useEffect, useState } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import useHappyApi from './helpers/useHappyApi.js';
import {SessionContext} from './session.js';
import Chart from 'react-apexcharts';
import Apexcharts from 'apexcharts';
import styles from './css/dashboard.module.css';
import { useWindowSize } from '@react-hook/window-size/throttled';
import Item from './item.js'
import { Redirect } from "react-router-dom";

const Dashboard = () => {
  const { session } = useContext(SessionContext);
  if (!session) {return(<Redirect to="/" />);}
  const [width, height] = useWindowSize();
  const [companyHappinessState] = useHappyApi(`${process.env.API_ENDPOINT}/company/happiness`);
  const [happinessValue, setHappinessValue] = useState(0);
  const [mainSeries, setMainSeries] = useState([]);
  const [votesSeries, setVotesSeries] = useState([]);
  const [weekSeries, setWeekSeries] = useState([]);
  const [todaySeries, setTodaySeries] = useState([]);
  const [monthSeries, setMonthSeries] = useState([]);
  const [weekValue, setWeekValue] = useState(0);
  const [todayValue, setTodayValue] = useState(0);
  const [monthValue, setMonthValue] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState();
  const [groups, setGroups] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [redirectToRequestAccess, setRedirectToRequestAccess] = useState(false);
  const [mainOptions, setMainOptions] = useState({
    chart: {
      id: "mainChart",
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      },
      animations: {
        enabled: false
      },
      events: {
        legendClick: (chartContext, seriesIndex, config) => {
          const { series } = config.config;
          const selectedSeriesItemName = series[seriesIndex].name;
          series.forEach((seriesItem) => {
            const currentSeriesItemName = seriesItem.name;
            if (currentSeriesItemName !== selectedSeriesItemName) {
              chartContext.hideSeries(currentSeriesItemName);
            }
          });
          chartContext.showSeries(selectedSeriesItemName);
          // ...
        },
      },
    },
    stroke: {
      width: [1,5],
      curve: 'straight'
    },
//    labels: [1, 2, 3],
    title: {
      text: 'Company and Groups Happiness'
    },
    dataLabels: {
      enabled: false
    },
    yaxis: {
      min: 100,
      max: 500,
      tickAmount: 4,
      labels: {
        style: {
          fontSize: '25px',
        },
        formatter: function (val) {
          if (val <= 100) { return "😠"; } else
          if (val <= 200) { return "🙁"; } else
          if (val <= 300) { return "😐"; } else
          if (val <= 400) { return "🙂"; } else
          if (val <= 500) { return "😂"; }
        },
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'dd/MM',
      }
    },
  });
  const [votesOptions, setVotesOptions] = useState({
    chart: {
      id: "votesChart",
      height: 350,
      type: 'bar',
      events: {
        legendClick: (chartContext, seriesIndex, config) => {
          const { series } = config.config;
          const selectedSeriesItemName = series[seriesIndex].name;
          series.forEach((seriesItem) => {
            const currentSeriesItemName = seriesItem.name;
            if (currentSeriesItemName !== selectedSeriesItemName) {
              chartContext.hideSeries(currentSeriesItemName);
            }
          });
          chartContext.showSeries(selectedSeriesItemName);
          // ...
        },
      },
    },
//    labels: [1, 2, 3],
    title: {
      text: 'Number of Votes'
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: 'rounded'
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'dd/MM',
      }
    },
  });
  const selectGroup = (index, name) => {
    if (selectedGroup != index) {
      setSelectedGroup(index);
      if (index == 0) {
        groups.forEach((item, itemIndex) => {
          const currentSeriesItemName = item.name;
          showSeries(currentSeriesItemName)
        });
      } else {
        groups.forEach((item, itemIndex) => {
          const currentSeriesItemName = item.name;
          if (currentSeriesItemName !== name) {
            hideSeries(currentSeriesItemName);
          }
        });
        showSeries(name);
      }
    }

  }

  useEffect(() => {
    if (todaySeries[selectedGroup]) { setTodayValue(todaySeries[selectedGroup].data) }
    if (todaySeries[selectedGroup]) { setWeekValue(weekSeries[selectedGroup].data) }
    if (todaySeries[selectedGroup]) { setMonthValue(monthSeries[selectedGroup].data) }
  }, [selectedGroup])

  useEffect(() => {

    const updateStateData = () => {
      //let company_metrics = [];
      setMainSeries(Object.entries(companyHappinessState.data).map(([key,value]) => {
        return({name: (key=='company')?key:value[0].name, data: value.map(metrics => [new Date(metrics.date).getTime(), metrics.value]).reverse()}); }
      ));
      setVotesSeries(Object.entries(companyHappinessState.data).map(([key,value]) => {
        return({name: (key=='company')?key:value[0].name, data: value.map(metrics => [new Date(metrics.date).getTime(), metrics.votes]).reverse()}); }
      ));
      setWeekSeries(Object.entries(companyHappinessState.data).map(([key,value]) => {
        let filteredValue = value.filter((item) => {
          if (new Date(item.date).getTime() > new Date().setDate(new Date().getDate() - 7))
            return item.date;
          });
        let weekArray = filteredValue.map((item) => item.value);
        let weekAverage = weekArray.reduce((total, metric) => { return(total + metric) }, 0) /weekArray.length;
        return({name: (key=='company')?key:value[0].name, data: weekAverage });
      }).reverse());
      setTodaySeries(Object.entries(companyHappinessState.data).map(([key,value]) => {
        let filteredValue = value.filter((item) => {
          if (new Date(item.date).getTime() >= new Date().setUTCHours(0,0,0,0) && new Date(item.date).getTime() <= new Date().setUTCHours(23,59,59,999))
            return item.date;
          });

        let todayArray = filteredValue.map((item) => item.value);
        let todayAverage = todayArray.reduce((total, metric) => { return(total + metric) }, 0) /todayArray.length;
        return({name: (key=='company')?key:value[0].name, data: todayAverage });
      }).reverse());

      setGroups(Object.entries(companyHappinessState.data).map(([key,value]) => {
        return({name: (key=='company')?key:value[0].name});
      }).reverse());

      setMonthSeries(Object.entries(companyHappinessState.data).map(([key,value]) => {
        let filteredValue = value.filter((item) => {
          if (new Date(item.date).getTime() > new Date().setDate(new Date().getDate() - 30))
            return item.date;
          });
        let monthArray = filteredValue.map((item) => item.value);
        let monthAverage = monthArray.reduce((total, metric) => { return(total + metric) }, 0) /monthArray.length;
        return({name: (key=='company')?key:value[0].name, data: monthAverage });
      }).reverse());

      let tempOptions = mainOptions;
      tempOptions.labels = companyHappinessState.data.company.map(metrics => metrics.date)
      tempOptions.xaxis.min = new Date(companyHappinessState.data.company[companyHappinessState.data.company.length -1]).getTime();
      tempOptions.xaxis.tickAmount = companyHappinessState.data.company.length -1;
      setMainOptions(tempOptions);
      let tempVotesOptions = votesOptions;

      tempVotesOptions.plotOptions.bar.columnWidth = `${100/Object.keys(companyHappinessState.data).length}%`
      tempVotesOptions.labels = companyHappinessState.data.company.map(metrics => metrics.date)
      tempVotesOptions.xaxis.min = new Date(companyHappinessState.data.company[companyHappinessState.data.company.length -1]).getTime();
      tempVotesOptions.xaxis.tickAmount = companyHappinessState.data.company.length -1;
      setVotesOptions(tempVotesOptions);
      setHappinessValue(Number(companyHappinessState.data.company[0]?.value || 0));
      selectGroup(0, 'company');
    }
    if (companyHappinessState.data){setLoading(false);}
    const requestAccess=() => {setRedirectToRequestAccess(true)}

    if (companyHappinessState.data && companyHappinessState.data.requestAccess == true) { requestAccess(); }
    else if (companyHappinessState.data) { updateStateData(); }

  }, [companyHappinessState.data])

  useEffect(() => {
    console.log(mainSeries)
  }, [mainSeries])

  useEffect(() => {
  }, [weekSeries])

  useEffect(() => {
  }, [monthSeries])

  useEffect(() => {
  }, [todaySeries])

  useEffect(() => {
  }, [mainOptions])

  const toggle = (series) => {
    ApexCharts.exec('mainChart', 'toggleSeries', series);
    ApexCharts.exec('votesChart', 'toggleSeries', series);
  }

  const hideSeries = (series) => {
    ApexCharts.exec('mainChart', 'hideSeries', series);
    ApexCharts.exec('votesChart', 'hideSeries', series);
  }

  const showSeries = (series) => {
    ApexCharts.exec('mainChart', 'showSeries', series);
    ApexCharts.exec('votesChart', 'showSeries', series);
  }

  const RenderGroups = () => {
    return groups.map( (group, index) => <Item key={index} index={index} name={group.name} mode="dashboardGroup" selected={(index==selectedGroup)} selectDashboardGroup={selectGroup}/>)
  }

  const renderSpeedometer = (period, value, width) => {
    return(<div className={styles.speedometer}>
      <b>{period}</b>
      <ReactSpeedometer
        value={value||0}
        width={(width*0.60)/3}
        height={(((width*0.60)/3)/2)+60}
        //forceRender={true}
        maxValue={500}
        currentValueText={value?"Happiness Level":"No Data"}
        customSegmentLabels={[
          {
            text: "😠",
            position: "INSIDE",
            color: "#555",
            fontSize: "35px",
          },
          {
            text: "🙁",
            position: "INSIDE",
            color: "#555",
            fontSize: "35px",
          },
          {
            text: "😐",
            position: "INSIDE",
            color: "#555",
            fontSize: "35px",
          },
          {
            text: "🙂",
            position: "INSIDE",
            color: "#555",
            fontSize: "35px",
          },
          {
            text: "😂",
            position: "INSIDE",
            color: "#555",
            fontSize: "35px",
          },
        ]}
      />
    </div>);
  }

//<button value="company" onClick={toggle}>company</button> // company show all,... others sow only other.
  if (redirectToRequestAccess) {return(<Redirect to="/request-access" />);}
//  if (isLoading) {
//    return <div className={styles.dashboard}><div className={styles.loader}></div></div>;
//  }
  return (
    <div className={styles.dashboard}>
      <div className={styles.row}><h2 className={styles.header}>Dashboard</h2>
        <div className={styles.chartColumn}>
          <div className={styles.columntext}><b>Overview</b></div>
          <div className={styles.speedometers}>
            {renderSpeedometer('Today', todayValue, width)}
            {renderSpeedometer('Week', weekValue, width)}
            {renderSpeedometer('Month', monthValue, width)}
          </div>
          <div>
            <Chart options={mainOptions} series={mainSeries} type="line" height={350} />
          </div>
          <div>
            <Chart options={votesOptions} series={votesSeries} type="bar" height={350} />
          </div>
        </div>
        <div className={styles.settingsColumn}>
          <div className={styles.columntext}>settings</div>
            {isLoading && <div className={styles.loader}></div>}
            {!isLoading && <RenderGroups/>}
        </div>
      </div>
    </div>
    );
}


export default Dashboard;
