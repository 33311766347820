import React, { useContext, useState } from "react";
import {SessionContext} from './session.js';
import styles from './css/help.module.css';
import {
  Redirect
} from "react-router-dom";



// css to text exei aspro xrwma
const Help = () => {
  const { session } = useContext(SessionContext);

  if (!session) {return(<Redirect to="/" />);}
  return (
    <div className={styles.help}>
      <div className={styles.void}><br/></div>
      <div className={styles.row}>
        <div className={styles.column}>
          <h1 className={styles.title}>How To Use</h1>
          <br/>
          <p className={styles.paragraph}>
            Two different products are offered in the form of subscriptions, the "Unhappy Button" subscription and the "Happinsights" subscription. It is up to you to select one or both of them. <br/>
            <br/>In the "Unhappy Button" subscription a single button is added on the Happinsights Home tab on Slack App that is accessible to all the members of your team. When pressed it sends a message
            to a predifened channel of your choosing, informing you that someone from your team is unhappy. Furthermore the "Happinsights" subscription sends a happiness survey on a predifined interval
            on selected members of your team on the Happinsights Messages tab.<br/>
          </p>
          <br/>
          <p className={styles.paragraph}>
            You can enable or disable the "Unhappy Button" subscription from the settings by adding a channel and enabling or disabling the switch. You can find more information by pressing
            the button help me on the settings page.
          </p>
          <br/>
          <p className={styles.paragraph}>
            For the "Happinsights" subscription your First step would be to go to settings (top item on the menu) and add a new Group by pressing on the Add new Group field on Settings page.<br/>
            After you have at least one Group, you click on that Group and start adding People from your Slack Organization.<br/>
            Finally You set up the interval (Daily, Weekly or Monthly).
          </p>
          <br/>
          <p className={styles.paragraph}>
            Depending on your choices you can expect the first Happiness Survey message to start appear on your Organizations Slack
            the next Day/Week/Month.<br/>
            We currently have 3 schedulers for daily weekly and monthly messages.<br/>
            The Daily messanger will run every Working Day (Mon-Fri) at 00:00 UTC and schedule the messages to be delivered based on the time you have set.<br/>
            The Weekly messanger will run every Thursday at 00:00 and schedule the messages to be delivered based on the time you have set on Friday. <br/>
            The Monthly messanger will run every 1st of Month at 00:00 and schedule the messages to be delivered based on the time you have set on First Working day. <br/>
          </p>
          <br/>
          <p className={styles.paragraph}>
            As votes are counted you can observe results on your Organization Happiness Dashboard (second item on the menu)<br/>
          </p>
          <br/>
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.column}>
          <h1 className={styles.title}>Support</h1>
          <br/>
            <p className={styles.paragraph}>
          Email us: happinsights@gmail.com and info@happinsights.com<br/>
          Call us: +4571846567<br/>
          Discord: <a href="https://discord.gg/3tXeKsTnRG">Chat live on our Discord Channel</a> <br/></p>
          <br/>
          <br/>
        </div>
      </div>
    </div>
  );
}

export default Help
