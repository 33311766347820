import React, { useRef, useEffect, useState }  from "react";
import styles from './css/item.module.css';
import AddIcon from './icons/add-icon.js';
import DoneIcon from './icons/done-icon.js';
import Avatar from "react-user-avatar";
import DeleteIcon from './icons/delete-icon.js';
import EditIcon from './icons/edit-icon.js';
import SearchIcon from './icons/search-icon.js';

const Item = (props) => {
  const addInp = useRef(null);
  const editInp = useRef(null);
  const filterInp = useRef(null);
  const [editMode, setEditMode] = useState(false);
  useEffect(() => {
    if (editInp.current){
      editInp.current.value = props.name;
      editInp.current.focus();
    }
  }, [editMode])
  const handleAddClick = () => {
    if (addInp.current){ addInp.current.focus() }
  }
  const handleFilterClick = () => {
    if (filterInp.current){ filterInp.current.focus() }
  }

  const clearFilterClick = () => {
    if (filterInp.current){
      filterInp.current.value = null;
      props.filterPeople(filterInp.current.value);
    }
  }

  const handleKeyPress = (event) => {
    if(event.key === 'Enter') {
      if (props.mode == 'add') {
        addInp.current.blur()
        addGroup();
      }
      if (editMode) {
        console.log('edit enter');
        editInp.current.blur()
      }

    }
  }
  const handleFilterKeyPress = (event) => {
    props.filterPeople(filterInp.current.value);
  }
  const handleFilterKeyPressinnactive = (event) => {
    filterInp.current.value = null;
  }

  const handleSelectGroupClick = () => {
    props.selectGroup(props.index);
  }
  const handleSelectDashbpardGroupClick = () => {
    props.selectDashboardGroup(props.index, props.name);
  }
  const addGroup = () => {
    props.addGroup(addInp.current.value);
    addInp.current.value = "";
  }
  const editGroup = () => {
    if (editInp.current.value.trim() != "") {
      if (editInp.current.value != props.name) {
        props.editGroup({index: props.index, name: editInp.current.value});
      }
      setEditMode(false)
      editInp.current.value = "";
    }
  }
  const handleAddMemberClick = () => {
    if (props.active){
      props.addGroupMember(props.index);
    }
  }

  const handleRemoveGroupMemberClick = () => {
    props.removeGroupMember(props.index);
  }

  const handleDeleteGroupClick = () => {
    props.deleteGroup(props.index);
  }

  const handleEditGroupClick = () => {
    setEditMode(true)
  }

  if (props.mode=='add'){
    return (
      <div className={styles.item} onClick={handleAddClick} onKeyPress={handleKeyPress} >
        <div className={styles.left}><AddIcon viewBox="0 0 24 24" width="2em" height="2em"/></div>
        <div className={styles.center}><input ref={addInp} type="text" className={styles.input} placeholder="Add Group" onBlur={addGroup}/></div>
        <div className={styles.right}>></div>
      </div>
    )
  }

  if (props.mode=='filter'){
    return (
      <div className={(props.active)?styles.item:styles.iteminnactive} onClick={(props.active)?handleFilterClick:null} onKeyUp={(props.active)?handleFilterKeyPress:handleFilterKeyPressinnactive} >
        <div className={styles.left}><SearchIcon viewBox="0 0 24 24" width="2em" height="2em"/></div>
        <div className={styles.center}><input ref={filterInp} type="text" className={styles.input} placeholder="Filter..."/></div>
        <div className={styles.right} onClick={clearFilterClick}>x</div>
      </div>
    )
  }

  if (props.mode=='people') {
    return(
      <div className={(props.active)?styles.item:styles.iteminnactive} onClick={handleAddMemberClick}>
        <div className={styles.left}>&lt;</div>
        <div className={styles.center}>{props.name}</div>
        <div className={styles.right}><Avatar size="30" name={props.name} src={props.image_32}/></div>
      </div>
    )
  }

  if (props.mode=='groupPeople') {
    return(
      <div className={styles.item} onClick={handleRemoveGroupMemberClick}>
        <div className={styles.left}><Avatar size="30" name={props.name} src={props.image_32}/></div>
        <div className={styles.center}>{props.name}</div>
        <div className={styles.right}>></div>
      </div>
    )
  }

  const RenderGroupIcons = () => {
    if (props.selected) {
      return (
        <div className={styles.icons}>
          <div className={styles.delete} onClick={handleDeleteGroupClick}>
            <DeleteIcon svgClass={styles.svg} viewBox="0 0 24 24" width="1.4em" height="1.4em"/>
          </div>
          <div className={styles.edit} onClick={handleEditGroupClick}>
            <EditIcon svgClass={styles.svg} viewBox="0 0 24 24" width="1.4em" height="1.4em"/>
          </div>
        </div>
      );
    } else return null;
  }

  const renderEditGroupName = () => {
    return (<input ref={editInp} type="text" className={styles.input} placeholder={props.name} onBlur={editGroup} onKeyPress={handleKeyPress}/>)
  }

  if (props.mode=='group') {
    return(
      <div className={styles.group}>
        <div className={(props.selected)?[styles.item, styles.selected].join(" "):styles.item} onClick={handleSelectGroupClick}>
          <div className={styles.left}><Avatar size="30" name={props.name} src={props.src}/></div>
          <div className={styles.center}>{editMode?renderEditGroupName():props.name}</div>
          <div className={styles.right}>></div>
        </div>
        <RenderGroupIcons />
      </div>
    )
  }

  if (props.mode=='dashboardGroup') {
    return(
      <div className={styles.group}>
        <div className={(props.selected)?[styles.item, styles.selected].join(" "):styles.item} onClick={handleSelectDashbpardGroupClick}>
          <div className={styles.left}>&lt;</div>
          <div className={styles.center}>{props.name}</div>
          <div className={styles.right}><Avatar size="30" name={props.name}/></div>
        </div>
      </div>
    )
  }

  return(
    <div className={(props.selected)?[styles.item, styles.selected].join(" "):styles.item} onClick={handleSelectGroupClick}>
      <div className={styles.left}><Avatar size="30" name={props.name} src={props.src}/></div>
      <div className={styles.center}>{props.name}</div>
      <div className={styles.right}>></div>
    </div>
  )
}

export default Item;
