import * as React from "react";

function SvgDone24Px(props) {
  return (
    <svg className={props.svgClass} height={props.height} viewBox={props.viewBox} width={props.width}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" fill={props.fill}/>
    </svg>
  );
}

export default SvgDone24Px;
