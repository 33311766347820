import * as React from "react";

function SvgEdit24Px(props) {
  return (
    <svg className={props.svgClass} height={props.height} viewBox={props.viewBox} width={props.width}>
      <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" fill={props.fill} />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
}

export default SvgEdit24Px;
