import React, { useContext, useState, useEffect } from "react";
import {SessionContext} from './session.js';
import styles from './css/addToSlack.module.css';
import useHappyApi from './helpers/useHappyApi.js';
import {
  Redirect,
  useLocation
} from "react-router-dom";
//import ky from 'ky';
import queryString from 'query-string'


// css to text exei aspro xrwma
const AddToSlack = () => {
  const { session } = useContext(SessionContext);
  const [slackState] = useHappyApi(`${process.env.STRIPE_ENDPOINT}/get-state`);// [{ companyData, companyIsLoading, companyIsError }, refetchData] = useHappyApi("companies");
  const [addToSlackUrl, setAddToSlackUrl] = useState('#');
  const [addToSlackUrlLocal, setAddToSlackUrlLocal] = useState('#');
  const [stateLoading, setStateLoading] = useState(true);

  useEffect(() => {

    const setState = () => {
      setAddToSlackUrl("https://slack.com/oauth/v2/authorize?client_id=738337750161.747056655510&state=" + slackState.data.body +"&scope=chat%3Awrite%2Cusers%3Aread&redirect_uri=https://dev.happinsights.com/dev/auth/redirect")
      setAddToSlackUrlLocal("https://slack.com/oauth/v2/authorize?client_id=738337750161.747056655510&state=" + slackState.data.body + "&scope=chat%3Awrite%2Cusers%3Aread&redirect_uri=https://happinsights.loca.lt/auth/redirect")
      setStateLoading(false)
    };

    if (slackState.data && slackState.data.body) {setState(); }

  },[slackState])

//  let addToSlackUrl = "https://slack.com/oauth/v2/authorize?client_id=738337750161.747056655510&state=" + slackState +"&scope=chat%3Awrite%2Cusers%3Aread&redirect_uri=https://dev.happinsights.com/dev/auth/redirect"
//  let addToSlackUrlLocal = "https://slack.com/oauth/v2/authorize?client_id=738337750161.747056655510&state=" + slackState + "&scope=chat%3Awrite%2Cusers%3Aread&redirect_uri=https://happinsights.loca.lt/auth/redirect"
  //let addToSlackUrlLocal = "https://slack.com/oauth/v2/authorize?client_id=738337750161.747056655510&state=" + slackState + "&scope=users%3Aread%2Cchat%3Awrite&user_scope=openid%2Cprofile%2Cemail&redirect_uri=https%3A%2F%2Fdev.happinsights.com%2Fdev%2Fauth%2Fslackauth";

  let error = '';

  if (queryString.parse(useLocation().search).error == "installation-failed") {
    error = 'We apologize for the inconvenience, but it appears that an issue has occurred while attempting to add HappInsights to your organization. Our team has been notified and is currently working to resolve the problem. We ask that you please try again at a later time.';
  }

  const requestAddMessage = () => {
      return (
          <div>
          {(error!='')?<div>
              <div className={styles.error}>{error}</div></div>:<div/>}
            <p  className={ styles.paragraph}>In order to use this app for your organization you need to Add Happinsights to Slack.</p>
            <p className={ styles.paragraph}>Please press the button "Add to Slack" below this text to do so.</p>
            <div className={styles.addtoslack}>
            {(process.env.NODE_ENV === "production")?<a href={addToSlackUrl}><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>:
               <a href={addToSlackUrlLocal}><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
            }
            </div>
          </div>
        )
  }
  if (!session) {return(<Redirect to="/" />);}
  return (
    <div className={styles.request_access}>
      <div className={styles.void}><br/></div>
      {stateLoading?<div className={styles.loader}></div>:requestAddMessage()}
    </div>
  );
}

export default AddToSlack
