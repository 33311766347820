import React, { useEffect, useState, useContext, useReducer }  from "react";
import {SessionContext} from '../session.js';
import ky from 'ky';

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        type: action.type,
        isLoading: true,
        isError: false
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        type: action.type,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        type: action.type,
        isLoading: false,
        isError: true,
      };
    case 'PUT_INIT':
      return {
        ...state,
        type: action.type,
        isLoading: true,
        isError: false
      };
    case 'PUT_SUCCESS':
      return {
        ...state,
        type: action.type,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case 'PUT_FAILURE':
      return {
        ...state,
        type: action.type,
        isLoading: false,
        isError: true,
      };
    case 'POST_INIT':
      return {
        ...state,
        type: action.type,
        isLoading: true,
        isError: false
      };
    case 'POST_SUCCESS':
      return {
        ...state,
        type: action.type,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case 'POST_FAILURE':
      return {
        ...state,
        type: action.type,
        isLoading: false,
        isError: true,
      };
    case 'DELETE_INIT':
      return {
        ...state,
        type: action.type,
        isLoading: true,
        isError: false
      };
    case 'DELETE_SUCCESS':
      return {
        ...state,
        type: action.type,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case 'DELETE_FAILURE':
      return {
        ...state,
        type: action.type,
        isLoading: false,
        isError: true,
      };
    default:
      throw new Error();
  }
};

const useHappyApi = (initUrl) => {
  const { session } = useContext(SessionContext);
  const [url, setUrl] = useState(initUrl);
  const [withFetch, setWithFetch] = useState(true);
  const [putData, setPutData] = useState();
  const [postData, setPostData] = useState();
  const [deleteData, setDeleteData] = useState();
  const [reFetchCount, setRefetchCount] =  useState(0);

  const refetch = () => {
    setRefetchCount( reFetchCount => reFetchCount + 1 );
  }

//  const setEndpoint = (params) => {
//    console.log("invoked "+fetchEndpoint);
//    console.log(params);
//    switch(fetchEndpoint){
//      case "companies":
//        console.log("setting to companies")
//        console.log(params)
//        setUrl(`${process.env.API_ENDPOINT}/companies`);
//        return true;
//        break;
//      case "groups":
//        console.log("setting to groups")
//        console.log(params)
//        if (params){
//          console.log("a");
  //        console.log(url);
//          console.log(params);
//          setUrl(`${process.env.API_ENDPOINT}/companies/${params.company_id}/groups`);
//          console.log(url)
//          setUrl('la');
//          console.log(url)
//          return true;
//        } else { return false; }
//        break;
//    }
//  }

  //setEndpoint();

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    data: null,
  });

  const api = ky.extend({
     hooks: {
         beforeRequest: [
              request => {
                    request.headers.set('Authorization', session);
              }
         ]
     }
   });

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });
      try {
        const result = await api.get(url).json();

        if (!didCancel) {
          dispatch({ type: 'FETCH_SUCCESS', payload: result });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: 'FETCH_FAILURE' });
        }
      }
    };

    if (session && url && withFetch) { fetchData(); }

    return () => {
      didCancel = true;
    };
  }, [url, reFetchCount]);

  useEffect(() => {
    let didCancel = false;

    const putRequest = async () => {
      dispatch({ type: 'PUT_INIT' });
      try {
        const result = await api.put(url, {json : putData}).json();
        if (!didCancel) {
          dispatch({ type: 'PUT_SUCCESS', payload: result });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: 'PUT_FAILURE' });
        }
      }
    };

    if (session && putData) {
      putRequest();
    }

    return () => {
      didCancel = true;
    };
  }, [putData]);

  useEffect(() => {
    let didCancel = false;

    const postRequest = async () => {
      dispatch({ type: 'POST_INIT' });
      try {
        const result = await api.post(url+`\\${postData.id}`, {json : postData.data}).json();
        if (!didCancel) {
          dispatch({ type: 'POST_SUCCESS', payload: result });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: 'POST_FAILURE' });
        }
      }
    };

    if (session && postData) {
      postRequest();
    }

    return () => {
      didCancel = true;
    };
  }, [postData]);

  useEffect(() => {
    let didCancel = false;

    const deleteRequest = async () => {
      dispatch({ type: 'DELETE_INIT' });
      try {
        const result = await api.delete(url+`\\${deleteData.id}`).json();
        if (!didCancel) {
          dispatch({ type: 'DELETE_SUCCESS', payload: result });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: 'DELETE_FAILURE' });
        }
      }
    };

    if (session && deleteData) {
      deleteRequest();
    }

    return () => {
      didCancel = true;
    };
  }, [deleteData]);

  return [state, setUrl, setPutData, refetch, setDeleteData, setPostData, setWithFetch];
};


export default useHappyApi;
