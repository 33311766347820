import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation
} from "react-router-dom";

import './components/css/happinsights.css';
import styles from './components/css/app.module.css';


import SideBar from './components/sidebar.js';
import NavBar from './components/navbar.js';
import Dashboard from './components/dashboard.js';
import PrivacyPolicy from './components/privacyPolicy.js';
import Tos from './components/tos.js';
import Support from './components/support.js';

import Settings from './components/settings.js';
import Home from './components/home.js';
import Error from './components/error.js';
import RequestAccess from './components/requestAccess.js';
import AddToSlack from './components/addToSlack.js';
import Upgrade from './components/upgrade.js';
import Help from './components/help.js';
import {getSessionCookie, SessionContext} from './components/session.js';
import queryString from 'query-string'

const App = () => {
    const routes = [
      {
        path: "/",
        exact: true,
        sidebar: () => <h2>Home</h2>,//this.setState({isHomeSelected: true}),
        main: () => <Home /> // queryString.parse(useLocation().search).token ? <Settings /> : <Home />
      },
      {
        path: "/error",
        exact: true,
        sidebar: () => <h2>Error</h2>,//this.setState({isHomeSelected: true}),
        main: () => <Error /> // queryString.parse(useLocation().search).token ? <Settings /> : <Home />
      },
      {
        path: "/settings",
        sidebar: () => <h2>settings</h2>,// this.setState({isSettingsSelected: true}),
        main: () => <Settings />// this.state.logged ? <Settings /> : <Redirect to="/dashboard" />
      },
      {
        path: "/dashboard",
        sidebar: () => <h2>dashboard</h2>,//this.setState({isChartSelected: true}),
        main: () => <Dashboard />
      },
      {
        path: "/privacy-policy",
        sidebar: () => <h2>privacy-policy</h2>,//this.setState({isChartSelected: true}),
        main: () => <PrivacyPolicy />
      },
      {
        path: "/tos",
        sidebar: () => <h2>Terms of Servie</h2>,//this.setState({isChartSelected: true}),
        main: () => <Tos />
      },
      {
        path: "/support",
        sidebar: () => <h2>Support</h2>,//this.setState({isChartSelected: true}),
        main: () => <Support />
      },
      {
        path: "/request-access",
        sidebar: () => <h2>request-access</h2>,//this.setState({isChartSelected: true}),
        main: () => <RequestAccess />
      },
      {
        path: "/request-add-to-slack",
        sidebar: () => <h2>Add to Slack</h2>,//this.setState({isChartSelected: true}),
        main: () => <AddToSlack />
      },
      {
        path: "/subscribe",
        sidebar: () => <h2>subscribe</h2>,//this.setState({isChartSelected: true}),
        main: () => <Upgrade />
      },
      {
        path: "/help",
        sidebar: () => <h2>Help</h2>,//this.setState({isChartSelected: true}),
        main: () => <Help />
      }
    ];

    const renderRoutes = () => {
      return routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          children={<route.main />}
        />
      ))
    }

  const [session, setSession] = useState(getSessionCookie());
  useEffect(
    () => {
      setSession(getSessionCookie());
    },
    [session]
  );

  const updateSession = () => {
    setSession(getSessionCookie());
  }

  const renderSidebar = () => {
    if (session) {
      return <SideBar />
    }
  }

  return (
    <SessionContext.Provider value={{session, updateSession}}>
      <Router>
          <NavBar />
          <div className={session?styles.main_wrapper:styles.main_landing}>
            {renderSidebar()}
            <Switch>
              {renderRoutes()}
            </Switch>
          </div>
      </Router>
    </SessionContext.Provider>
    );
}


export default App;
