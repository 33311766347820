import React, { useEffect } from "react";
import * as Cookies from "js-cookie";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import queryString from 'query-string'

export const removeSessionCookie = () => {
  Cookies.remove("happinsights_session");
};

export const setSessionCookie = (token) => {
  Cookies.remove("happinsights_session");
  Cookies.set("happinsights_session", token, { expires: 60 });
};

export const getSessionCookie = () => {
  const sessionCookie = Cookies.get("happinsights_session");

  if (sessionCookie === undefined) {
    return false;
  } else {
    return sessionCookie;
  }
};

export const SessionContext = React.createContext(getSessionCookie());
