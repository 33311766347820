import useHappyApi from '../components/helpers/useHappyApi.js';

class StripeManager {

  async getOrCreateCustomer() {

  }

  async createCustomer() {
    try {
      // Retrieve email and username of the currently logged in user.
      // getUserFromDB() is *your* implemention of getting user info from the DB
      const { email, username } = getUserFromDB();
      if (!email || !username) {
        throw Error('Email or username not found.');
      }
      const request = await fetch('https://your-endpoint/stripe/create-customer', {
        method: 'POST',
        body: JSON.stringify({
          email,
          username,
        }),
      });
      const customer = (await request.json());
      // Update your user in DB to store the customerID
      // updateUserInDB() is *your* implementation of updating a user in the DB
      updateUserInDB({ customerID: customer.id });
      return customer;
    } catch (error) {
      //console.log('Failed to create customer');
      //console.log(error);
      return null;
    }
  }

  async getStripeCustomerID() {
    // Retrieve the current customerID from the currently logged in user
    // getUserFromDB() is *your* implemention of getting user info from the DB
    const { customerID } = getUserFromDB();
    if (!customerID) {
      const customer = await this.createCustomer();
      return customer?.id;
    }
    return customerID;
  }

  async createSubscription(customerID, paymentMethodID, session) {
    const request = await fetch(`${process.env.STRIPE_ENDPOINT}/create-subscription`, {
      method: 'PUT',
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': session,
      },
      body: JSON.stringify({
        customerID,
        paymentMethodID,
      }),
    });
    const subscriptionData = await request.json();
    if (subscriptionData.statusCode == 501){
      return subscriptionData.body.raw
    }
    //console.log(subscriptionData);
    const subscription = JSON.parse(subscriptionData.body)
  //  console.log(subscription);
    // Update your user in DB to store the subscriptionID and enable paid plan
    // updateUserInDB() is *your* implementation of updating a user in the DB
    //updateUserInDB({
    //  paymentMethodID,
    //  hasPaidPlan: true,
    //  subscriptionID: subscription.id,
    // });
    return subscription;
  }

  async pendingSubscription(subscriptionID, paymentMethodID, customerID, session) {
    //console.log("c");
    const request = await fetch(`${process.env.STRIPE_ENDPOINT}/pending-subscription`, {
      method: 'POST',
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': session,
      },
      body: JSON.stringify({
        customerID,
        paymentMethodID,
        subscriptionID,
      }),
    });
    const subscriptionData = await request.json();
    if (subscriptionData.statusCode == 501){
      return subscriptionData.body.raw
    }
    //console.log(subscriptionData)
    const subscription = JSON.parse(subscriptionData.body)
  //  console.log(subscription);
    // Update your user in DB to store the subscriptionID and enable paid plan
    // updateUserInDB() is *your* implementation of updating a user in the DB
    //updateUserInDB({
    //  paymentMethodID,
    //  hasPaidPlan: true,
    //  subscriptionID: subscription.id,
    // });
    return subscription;
  }

  async checkSubscription(paymentID, subscriptionID, customerID, session) {
  //paymentMethodID, subscription.id, hasPaidPlan, decoded.user_id, decoded.company_id
    const request = await fetch(`${process.env.STRIPE_ENDPOINT}/check-subscription`, {
      method: 'POST',
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': session,
      },
      body: JSON.stringify({
        paymentID,
        subscriptionID,
        customerID,
      }),
    });
    const subscriptionData = await request.json();
    console.log(subscriptionData);
    if (subscriptionData.statusCode == 501){
      return subscriptionData.body.raw
    }

    const subscription = JSON.parse(subscriptionData.body)
  //  console.log(subscription);
    // Update your user in DB to store the subscriptionID and enable paid plan
    // updateUserInDB() is *your* implementation of updating a user in the DB
    //updateUserInDB({
    //  paymentMethodID,
    //  hasPaidPlan: true,
    //  subscriptionID: subscription.id,
    // });
    return subscription;
  }
  async handleSubscription(subscriptionID, end) {
    const request = await fetch('https://your-endpoint/stripe/handle-subscription', {
      method: 'POST',
      body: JSON.stringify({
        subscriptionID,
        end,
      }),
    });
    return await request.json();
  }

  async cancelSubscription(subscriptionID, session, cancelation) {
    const request = await fetch(`${process.env.STRIPE_ENDPOINT}/cancel-subscription`, {
      method: 'POST',
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': session,
      },
      body: JSON.stringify({
        subscriptionID,
        cancelation,
      }),
    });
    const subscriptionData = await request.json();
    //console.log(subscriptionData);
    const subscription = JSON.parse(subscriptionData.body)
    //console.log(subscription);
    // Update your user in DB to store the subscriptionID and enable paid plan
    // updateUserInDB() is *your* implementation of updating a user in the DB
    return subscription;
  }

  async updateSubscription(subscriptionID, session, quantity,unhappyQuantity, priceID, unhappyPriceID) {
    const request = await fetch(`${process.env.STRIPE_ENDPOINT}/update-subscription`, {
      method: 'POST',
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': session,
      },
      body: JSON.stringify({
        subscriptionID,
        quantity,
        unhappyQuantity,
        priceID,
        unhappyPriceID,
      }),
    });
    const subscriptionData = await request.json();
    //console.log(subscriptionData);
    const subscription = JSON.parse(subscriptionData.body)
    //console.log(subscription);
    // Update your user in DB to store the subscriptionID and enable paid plan
    // updateUserInDB() is *your* implementation of updating a user in the DB
    return subscription;
  }

  async retrieveSubscription(subscriptionID, session) {
    const request = await fetch(`${process.env.STRIPE_ENDPOINT}/retrieve-subscription`, {
      method: 'POST',
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': session,
      },
      body: JSON.stringify({
        subscriptionID,
      }),
    });
    const subscriptionData = await request.json();
    //console.log(subscriptionData);
    if (subscriptionData.statusCode == 200){
      const subscription = JSON.parse(subscriptionData.body)
      //console.log(subscription);
      // Update your user in DB to store the subscriptionID and enable paid plan
      // updateUserInDB() is *your* implementation of updating a user in the DB
      return subscription;
    } else { return {}};
  }

  async updatePaymentMethod(customerID, paymentMethodID) {
    await fetch('https://your-endpoint/stripe/update-payment-method', {
      method: 'POST',
      body: JSON.stringify({
        customerID,
        paymentMethodID,
      }),
    });
    // Update your user in DB to store the new payment method
    // updateUserInDB() is *your* implementation of updating a user in the DB
    updateUserInDB({ paymentMethodID });
  }

  async retreivePaymentInfo(paymentMethodID) {
    try {
      const request = await fetch(
        'https://your-endpoint/stripe/retrieve-payment-method',
        {
          method: 'POST',
          body: JSON.stringify({
            paymentMethodID,
          }),
        }
      );
      const result = await request.json();
      return {
        type: result.card.brand,
        digits: result.card.last4,
      };
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async retryInvoice(customerID, paymentMethodID, invoiceID) {
    const request = await fetch('https://your-endpoint/stripe/retry-invoice', {
      method: 'POST',
      body: JSON.stringify({
        customerID,
        paymentMethodID,
        invoiceID,
      }),
    });
    await request.json();
  }

}
export default StripeManager;
