import React, { useState, useContext, useEffect } from "react";
import styles from './css/sidebar.module.css';
import useHappyApi from './helpers/useHappyApi.js';

import './css/sideBar.css';
import {
  Link,
  useLocation
} from "react-router-dom";
import SettingsIcon from './icons/settings-icon.js';
import ChartIcon from './icons/chart-icon.js';
import SubscriptionIcon from './icons/subscription-icon.js';
import HomeIcon from './icons/home-icon.js';
import HelpIcon from './icons/help-icon.js';
import RefContext from '../context/refContext'

const SideBar = () => {
  const path = useLocation().pathname
  const [companiesState, , , reFetchCompanies] = useHappyApi(`${process.env.API_ENDPOINT}/companies`);
  const { add } = useContext(RefContext)
  useEffect(() => {
    add('sidebarReFetchCompanies', reFetchCompanies);
  }, []);
  let subscriptionRequired = () => {
    if (companiesState?.data?.companies[0]?.subscription_name != "FREE") {
      if (companiesState?.data?.companies[0]?.unhappy_subscription_switch == true && companiesState?.data?.companies[0]?.unhappy_subscription == false) {
        return "#ff0000";
      } else if (companiesState?.data?.companies[0]?.unhappy_subscription_switch == false && companiesState?.data?.companies[0]?.unhappy_subscription == true) {
        return "#0000FF"
      } else if (companiesState?.data?.companies[0]?.subscription_current_users > companiesState?.data?.companies[0]?.subscription_max_users) {
        return "#ff0000"
      } else if (companiesState?.data?.companies[0]?.subscription_current_users < companiesState?.data?.companies[0]?.subscription_max_users) {
        return "#0000FF"
      } else { return "#409040";}
    } else {
      return "#409040"
    }
  }

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebar_inner}>
        <ul className={styles.sidebar_list}>
          <li>
            <Link to="/settings"><SettingsIcon svgClass={(path === '/settings') ? "svgSelected" : styles.svgIcon} fill={(path === '/settings') ? "#2A7888" : "#409040"} viewBox="0 0 24 24" width="2em" height="2em"></SettingsIcon></Link>
          </li><hr />
          <li>
            <Link to="/dashboard"><ChartIcon svgClass={(path === '/dashboard') ? "svgSelected" : styles.svgIcon} fill={(path === '/dashboard') ? "#2A7888" : "#409040"} viewBox="0 0 24 24" width="2em" height="2em"></ChartIcon></Link>
          </li><hr />
          <li>
            <Link to="/subscribe"><SubscriptionIcon svgClass={(path === '/subscribe') ? "svgSelected" : styles.svgIcon} fill={(path === '/subscribe') ? "#2A7888" : subscriptionRequired()} viewBox="0 0 24 24" width="2em" height="2em"></SubscriptionIcon></Link>
          </li><hr />
        </ul>
        <ul className={styles.sidebar_list_bottom}>
          <li>
            <Link to="/help"><HelpIcon svgClass={(path === '/help') ? "svgSelected" : styles.svgIcon} fill={(path === '/help') ? "#2A7888" : "#409040"} viewBox="0 0 24 24" width="2em" height="2em"></HelpIcon></Link>
          </li>
        </ul>
      </div>
    </div>
    );
}

export default SideBar;
