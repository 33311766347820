import React, { useContext, useEffect, useState, useRef }  from "react";
import SideBar from './sidebar.js';
import {
  Redirect,
  useLocation
} from "react-router-dom";
import queryString from 'query-string'
import {SessionContext} from './session.js';
import styles from './css/settings.module.css';
import itemStyles from './css/item.module.css';

import Item from './item.js'
import DeleteIcon from './icons/delete-icon.js';
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ky from 'ky';
import 'regenerator-runtime/runtime';
import useHappyApi from './helpers/useHappyApi.js'
import Switch from "react-switch";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import RefContext from '../context/refContext'
import HelpIcon from './icons/help-icon.js';




const Settings = () => {
  const options = [
    { value: '1 day', label: 'Daily' },
    { value: '1 week', label: 'Weekly' },
    { value: '1 month', label: 'Monthly' }
  ]
  const { session } = useContext(SessionContext);
  const [companiesState] = useHappyApi(`${process.env.API_ENDPOINT}/companies`);// [{ companyData, companyIsLoading, companyIsError }, refetchData] = useHappyApi("companies");
  const [groupsState, setGroupsUrl, putGroupsRequest, reFetchGroup, deleteGroupRequest, postGroupsRequest] = useHappyApi();
  const [groupMembersState, setGroupMembersUrl, putGroupMembersRequest, reFetchGroupMembers, deleteGroupMembersRequest] = useHappyApi();
  const [companyMembersState, setCompanyMembersUrl, putCompanyMembersRequest, reFetchCompanyMembers] = useHappyApi();
  const [unHappySettingsState, setUnHappySettingsUrl, , reFetchUnHappySettings, , postUnHappySettingsRequest, setUnHappyWithFetch] = useHappyApi();
  const [groups, setGroups] = useState([]);
  const [people, setPeople] = useState([]);
  const [numberOfPeople, setNumberOfPeople] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [interval, setInterval] = useState(options[0]);
  const [selectedGroup, setSelectedGroup] = useState();
  const [groupsMembers, setGroupsMembers] = useState([]);
  const [showGroupMembers, setShowGroupMembers] = useState(false);
  const [filterPeople, setFilterPeople] = useState();
  const [redirectToRequestAccess, setRedirectToRequestAccess] = useState(false);
  const [redirectToAddToSlack, setRedirectToAddToSlack] = useState(false);
  const [groupsLoading, setGroupsLoading] = useState(true);
  const [groupMembersLoading, setGroupMembersLoading] = useState(false);
  const [companyMembersLoading, setCompanyMembersLoading] = useState(true);
  const [dateSettingsLoading, setDateSettingsLoading] = useState(false);
  const [unhappyStateLoading, setUnhappyStateLoading] = useState(true);
  const [unhappyState, setUnhappyState] = useState(false);
  const [unhappyStatus, setunhappyStatus] = useState("");
  const [unhappyError, setunhappyError] = useState("");
  const unhappyChannelRef = useRef(null);
  const { dispatch0 } = useContext(RefContext)
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const [openHappinsights, setOpenHappinsights] = useState(false);
  const closeModalHappinsights = () => setOpenHappinsights(false);
  // Function to collect data
  const [slackState] = useHappyApi(`${process.env.STRIPE_ENDPOINT}/get-state`);// [{ companyData, companyIsLoading, companyIsError }, refetchData] = useHappyApi("companies");
  const [addToSlackUrl, setAddToSlackUrl] = useState('#');
  const [addToSlackUrlLocal, setAddToSlackUrlLocal] = useState('#');

  useEffect(() => {

    const setState = () => {
      setAddToSlackUrl("https://slack.com/oauth/v2/authorize?client_id=738337750161.747056655510&state=" + slackState.data.body +"&scope=chat%3Awrite%2Cusers%3Aread&redirect_uri=https://dev.happinsights.com/dev/auth/redirect")
      setAddToSlackUrlLocal("https://slack.com/oauth/v2/authorize?client_id=738337750161.747056655510&state=" + slackState.data.body + "&scope=chat%3Awrite%2Cusers%3Aread&redirect_uri=https://happinsights.loca.lt/auth/redirect")
    };

    if (slackState.data && slackState.data.body) {setState(); }

  },[slackState])

//  let addToSlackUrl = "https://slack.com/oauth/v2/authorize?client_id=738337750161.747056655510&state=" + slackState +"&scope=chat%3Awrite%2Cusers%3Aread&redirect_uri=https://dev.happinsights.com/dev/auth/redirect"
//  let addToSlackUrlLocal = "https://slack.com/oauth/v2/authorize?client_id=738337750161.747056655510&state=" + slackState + "&scope=chat%3Awrite%2Cusers%3Aread&redirect_uri=https://happinsights.loca.lt/auth/redirect"
  //let addToSlackUrlLocal = "https://slack.com/oauth/v2/authorize?client_id=738337750161.747056655510&state=" + slackState + "&scope=users%3Aread%2Cchat%3Awrite&user_scope=openid%2Cprofile%2Cemail&redirect_uri=https%3A%2F%2Fdev.happinsights.com%2Fdev%2Fauth%2Fslackauth";


  useEffect(() => {

    if (unHappySettingsState.data?.status == "channelError") {
      setUnhappyStateLoading(false)
      console.log("channel error")
      setunhappyStatus("channelError");
      setunhappyError(unHappySettingsState.data?.message)
      setUnhappyState(false);
      unhappyChannelRef.current.value = "";
      unhappyChannelRef.current.focus();
    } else {

      setunhappyStatus("");
      setunhappyError("");
      if (unHappySettingsState.data) {
        setUnhappyStateLoading(false)
        setUnhappyState(unHappySettingsState.data?.unhappySubscriptionSwitch)
        unhappyChannelRef.current.value = unHappySettingsState.data?.channel
        unhappyChannelRef.current.blur();
      }
      dispatch0('sidebarReFetchCompanies');
      dispatch0('navbarReFetchCompanies');
    }
  }, [unHappySettingsState])

  useEffect(() => {

    if (selectedGroup>=0) {
      translateTime(groups[selectedGroup].time);
      translateInterval(groups[selectedGroup].interval);
      setDateSettingsLoading(false);
    }
  }, [selectedGroup])

  useEffect(() => {


    const updateCompanyData = () => {
      setUnhappyStateLoading(false)
      setGroupsUrl(`${process.env.API_ENDPOINT}/companies/${companiesState.data.companies[0].id}/groups`);
      setCompanyMembersUrl(`${process.env.API_ENDPOINT}/companies/${companiesState.data.companies[0].id}/members`);
      setUnHappyWithFetch(false);
      setUnHappySettingsUrl(`${process.env.API_ENDPOINT}/companies/${companiesState.data.companies[0].id}/unHappySettings`);
      setUnhappyState(companiesState.data.companies[0].unhappy_subscription_switch)
      unhappyChannelRef.current.value = companiesState.data.companies[0].unhappy_channel;
    }

    const requestAccess=() => {setRedirectToRequestAccess(true)}
    const requestAddToSlack=() => {setRedirectToAddToSlack(true)}

    if (companiesState.data && companiesState.data.requestAccess == true) { requestAccess(); }
    else if (companiesState.data && companiesState.data?.companies[0]?.subscription_name == 'NotAdded') { requestAddToSlack(); }
    else if (companiesState.data) { updateCompanyData(); }


  }, [companiesState.data])

  useEffect(() => {

    const initGroups = () => {
      setGroups(groupsState.data.groups);
      setGroupsLoading(false);
    };

    const addGroup = () => {
      setGroups([...groups, groupsState.data]);
      setGroupMembersUrl(`${process.env.API_ENDPOINT}/companies/${companiesState.data.companies[0].id}/groups/${groupsState.data.id}/members`);
      setShowGroupMembers(false);
      setGroupMembersLoading(true);
      setSelectedGroup(groups.length);
    };

    const updateGroups = () => {
      let index = groups.map(group => {return group.id;}).indexOf(groupsState.data.id);
      let updatedGroups = [...groups];
      updatedGroups[index]=groupsState.data;
      setGroups(updatedGroups);
      translateTime(groupsState.data.time);
      translateInterval(groupsState.data.interval);
    }

    const deleteGroup = () => {
      let index = groups.map(group => {return group.id;}).indexOf(groupsState.data.id);
      let updatedGroups = [...groups];
      updatedGroups.splice(index,1);
      setGroups(updatedGroups);
      setGroupsMembers();
      setShowGroupMembers(false);
      reFetchCompanyMembers();
    }

    if (groupsState.data && groupsState.type == 'FETCH_SUCCESS') {initGroups(); }
    if (groupsState.data && groupsState.type == 'PUT_SUCCESS') {addGroup(); }
    if (groupsState.data && groupsState.type == 'POST_SUCCESS') {updateGroups(); }
    if (groupsState.data && groupsState.type == 'DELETE_SUCCESS') {deleteGroup(); }

  },[groupsState.data])

  useEffect(() => {

    const updateCompanyMembers = () => {
      setPeople(companyMembersState.data.company_members)
      setNumberOfPeople(companyMembersState.data.company_members.length)
      setCompanyMembersLoading(false);
    };

    if (companyMembersState.data) {updateCompanyMembers(); }

  },[companyMembersState.data])

  useEffect(() => {

    const initGroupMembers = () => {
      setGroupsMembers(groupMembersState.data.group_members);
      setGroupMembersLoading(false);
      setShowGroupMembers(true);
    };

    const updateGroupMembers = () => {
      let index = people.map(member => {return member.id;}).indexOf(groupMembersState.data.id);
      let tempMembers = groupsMembers;
      tempMembers.push(people[index]);
      setGroupsMembers([...tempMembers]);
      let tempPeople = people;
      tempPeople.splice(index,1);
      setPeople([...tempPeople]);
      dispatch0('sidebarReFetchCompanies');
      dispatch0('navbarReFetchCompanies');
    };

    const deleteGroupMembers = () => {
      let index = groupsMembers.map(member => {return member.id;}).indexOf(groupMembersState.data.id);
      let tempMembers = groupsMembers;
      let groupMember = groupsMembers[index]
      groupsMembers.splice(index,1);
      setGroupsMembers([...tempMembers]);
      let tempPeople = people;
      tempPeople.push(groupMember);
      setPeople([...tempPeople]);
      dispatch0('sidebarReFetchCompanies');
      dispatch0('navbarReFetchCompanies');
    };

    if (groupMembersState.data && groupMembersState.type == 'FETCH_SUCCESS') {initGroupMembers(); }
    if (groupMembersState.data && groupMembersState.type == 'PUT_SUCCESS') {updateGroupMembers(); }
    if (groupMembersState.data && groupMembersState.type == 'DELETE_SUCCESS') {deleteGroupMembers(); }
  },[groupMembersState.data])

  const addGroup = (name) => {
    if (name.trim() != ""){
       putGroupsRequest({name: name});
     }
  }

  const selectGroup = (index) => {
    if (selectedGroup != index) {
      setGroupMembersUrl(`${process.env.API_ENDPOINT}/companies/${companiesState.data.companies[0].id}/groups/${groups[index].id}/members`);
      setShowGroupMembers(false);
      setGroupMembersLoading(true);
      setDateSettingsLoading(true);
      setSelectedGroup(index);
    }
  }

  const translateTime = (time) => {
    let chunked = time.split("+");
    let groupTime = chunked[0];
    let timezone = chunked[1];
    let groupTimeSplit = groupTime.split(":");
    let date = new Date();
    date.setHours(groupTimeSplit[0]);
    date.setMinutes(groupTimeSplit[1]);
    setStartDate(date);
  }

  const translateInterval = (groupInterval) => {
    if (groupInterval.days == 1) { setInterval(options[0]);}
    else if (groupInterval.days == 7) { setInterval(options[1]);}
    else if (groupInterval.months == 1) { setInterval(options[2]);}
  }

  const handleIntervalChange = (e) => {
    postGroupsRequest({id: groups[selectedGroup].id, data: {interval: e.value}})
  }

  const timezoneFinder = (timezoneOffset) => {
    let	offset_hrs = parseInt(Math.abs(timezoneOffset/60));
    let	offset_min = Math.abs(timezoneOffset%60);
    let timezone_standard;

    if(offset_hrs < 10)
    	offset_hrs = '0' + offset_hrs;

    if(offset_min < 10)
    	offset_min = '0' + offset_min;

    // Add an opposite sign to the offset
    // If offset is 0, it means timezone is UTC
    if(timezoneOffset <= 0)
    	timezone_standard = '+' + offset_hrs + ':' + offset_min;
    else if(timezoneOffset > 0)
    	timezone_standard = '-' + offset_hrs + ':' + offset_min;
    else if(timezoneOffset == 0)
    	timezone_standard = '+00:00';
    return timezone_standard;
  }

  const updateTime = (time) => {
    const addZero = (i) => {
      if (i < 10){
        i= "0" + i;
      }
      return i;
    }
    //console.log(time)
    let h = addZero(time.getHours());
    let m = addZero(time.getMinutes());
    let s = addZero(time.getSeconds());
    postGroupsRequest({id: groups[selectedGroup].id, data: {time: h + ":" + m + ":" + s + timezoneFinder(time.getTimezoneOffset())}})
    setStartDate(time)
  }

  const deleteGroup = (index) => {
    if (window.confirm("Deleting this Group will also Delete all the Happiness Metrics associated with this Group. This is irreversible. Are you sure?")) {
      setSelectedGroup();
      deleteGroupRequest({id: groups[index].id})
    }
  }

  const editGroup = (editData) => {
    postGroupsRequest({id: groups[editData.index].id, data: {name: editData.name}})
  }

  const addGroupMember = (index) => {
    putGroupMembersRequest({id: people[index].id});
  }

  const removeGroupMember = (index) => {
    deleteGroupMembersRequest({id: groupsMembers[index].id});
  }

  const handleAngrySwitch = (checked) => {
    setUnhappyStateLoading(true)
    setUnhappyState(checked)
    postUnHappySettingsRequest({id: '', data: {channelId: unhappyChannelRef.current.value, switch: checked}})


  }

  const RenderGroups = () => {
    return groups.map( (group, index) => <Item key={index} index={index} name={group.name} mode="group" selected={(index==selectedGroup)} selectGroup={selectGroup} deleteGroup={deleteGroup} editGroup={editGroup}/>)
  }

  const RenderPeople = () => {
    return people.map( (person, index) => {
      if (filterPeople){
        if (person.name.toLowerCase().includes(filterPeople.toLowerCase())) {
          return <Item key={index} index={index} name={person.name} image_32={person.image_32} mode="people" addGroupMember={addGroupMember} active={(selectedGroup>=0)}/>
        }
      } else {
        return <Item key={index} index={index} name={person.name} image_32={person.image_32} mode="people" addGroupMember={addGroupMember} active={(selectedGroup>=0)}/>
      }
    })
  }

  const RenderGroupPeople = () => {
    if (showGroupMembers) {
      return groupsMembers.map(  (person, index) => <Item key={index} index={index} name={person.name} image_32={person.image_32} mode="groupPeople" removeGroupMember={removeGroupMember} />);
    }
    else {
      return null;
    }
  }

  if (!session) {return(<Redirect to="/" />);}
  if (redirectToRequestAccess) {return(<Redirect to="/request-access" />);}
  if (redirectToAddToSlack) {return(<Redirect to="/request-add-to-slack" />);}

  return (
      <div className={styles.settings}>
        <div className={styles.settingsContainer}>
        {(queryString.parse(useLocation().search).reinstall == "force")?
        <div className={styles.reinstall}>
          <div className={styles.reinstallDescription}>
         If you have made changes to your Slack account, such as adding or removing users or altering time zones, please click the "Add to Slack" button to reinstall and retrieve the latest updates from your organization's Slack account.
         </div>
         <div className={styles.reinstallButton}>
         {(process.env.NODE_ENV === "production")?<a className={styles.reinstallSlackButon} href={addToSlackUrl}><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>:
            <a className={styles.reinstallSlackButon} href={addToSlackUrlLocal}><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
         }</div>
        </div>:null}


          <div className={styles.row}>
            <div className={styles.titleRow}>
              <h2 className={styles.header}>Unhappy Button Subscription Settings</h2>
              <div className={styles.helpModal} onClick={() => setOpen(o => !o)} >
                <HelpIcon svgClass={styles.svgIcon} fill={"#409040"} viewBox="0 0 24 24" width="2em" height="2em"></HelpIcon>
              </div>
            </div>
          <div className={styles.titleRow}>
          { !companiesState.data?<div className={styles.columnUnhappy}><div className={styles.columntext}>Channel ID</div><div className={styles.loader}></div></div>:
            <div className={styles.columnUnhappy}>
              <div className={styles.columntext}>Channel ID</div>
                <div className={styles.unhappyColumnChannel}>
                  <input ref={unhappyChannelRef} className={(unhappyStatus!="channelError")?itemStyles.item:itemStyles.itemError} type="text" name="channelId" placeholder="Channel ID:" />
                  <Popup open={open} closeOnDocumentClick onClose={closeModal} position="left center" modal>
                    <div className={styles.popuptext}>
                      The Unhappy Button, located in the HappInsights Home tab, allows for anonymous communication of unhappiness within your organization, triggering a message on a predefined Slack channel.
                      The subscription cost is $0.25 per person per month, with a minimum charge of $0.50.
                      If enabled it costs {(numberOfPeople * 0.25).toFixed(2)}$ per month for the {numberOfPeople} people in your organization.
                     <br/>
                     To set up the Unhappy Button Subscription:
                     <br/>
                     <ul className={styles.popuplist}>
                       <li>Select the Slack channel in which you wish to receive Unhappy updates and invite the HappInsights App to that channel,
                       either by typing "@HappInsights" in the message field, pressing send and then "Add to channel" in the popup that appears,
                       or by right clicking on the channel, selecting "View Channel Details" then selecting the tab "Integrations" and finally press
                       "Add an app" in the "Apps" section and press "Add" on HappInsights.</li>
                       <li>Locate the Channel ID for the selected channel by right-clicking on the channel and selecting "View Channel Details".
                       The Channel ID is located in the bottom of the About section.
                       Copy and paste the Channel ID into the appropriate field in the HappInsights settings.</li>
                       <li>Enable the Unhappy Button toggle in the settings to finalize setup. A welcome message will be sent to the selected channel,
                       and your organization members will have access to the Unhappy button in the HappInsights home tab.</li>
                     </ul>
                    </div>
                  </Popup>
                </div>
              </div>
            }
          { unhappyStateLoading?<div className={styles.columnUnhappy}><div className={styles.columntext}>Enable/Disable</div><div className={styles.loader}></div></div>:
            <div className={styles.columnUnhappy}>
              <div className={styles.columntext}>Enable/Disable</div>
              <div className={styles.angrySubscriptionSwitch}>
                <Switch onChange={handleAngrySwitch} checked={unhappyState} />
              </div>
            </div>
          }
          </div>
          </div>

          <div className={styles.row}>
          <div className={styles.titleRow}>
            <h2 className={styles.header}>Happinsights Survey Subscription Settings</h2>
            <div className={styles.helpModal} onClick={() => setOpenHappinsights(o => !o)} >
              <HelpIcon svgClass={styles.svgIcon} fill={"#409040"} viewBox="0 0 24 24" width="2em" height="2em"></HelpIcon>
            </div>
          </div>
          <Popup open={openHappinsights} closeOnDocumentClick onClose={closeModalHappinsights} position="left center" modal>
            <div className={styles.popuptext}>
              The HappInsights Survey is an effective tool for measuring the daily, weekly, or monthly happiness levels of selected employees within an organization.
              To properly set up the survey, the following steps should be taken:
              <br/>
                <ul className={styles.popuplist}>
                  <li>Create a new group by clicking on the "Add new Group" field. Press Enter or click anywhere on the screen to submit your group and proceed to the next step.</li>
                  <li>Once at least one group has been created, select the group and begin adding individuals from the available people within the organization by clicking on them.
                  It is recommended that groups consist of more than three individuals in order to ensure the anonymity of users through k-anonymity.</li>
                  <li>Set the desired interval and time for the survey, whether it be daily, weekly, or monthly.</li>
                </ul>
                According to your chosen interval and time, the first HappInsights Survey will appear in the HappInsight's messages tab at the next appropriate time.
                <br/>
                Given the functioning of our scheduling system, the initial messages can be expected to appear within a 24-hour period.
                The daily scheduler operates on a Monday-Friday schedule, initiating at 00:00 UTC daily.
                The weekly scheduler initiates every Thursday at 00:00 UTC, while the monthly scheduler begins on the first day of each month at 00:00 UTC.
                The scheduled delivery time for these messages will be based on the previously established time setting
            </div>
          </Popup>
            { groupsLoading?<div className={styles.column}><div className={styles.columntext}>Groups</div><div className={styles.loader}></div></div>:
              <div className={styles.column}>
                <div className={styles.columntext}>Groups</div>
                  <RenderGroups />
                  <Item name="+" mode="add" addGroup={addGroup}/>
                  {groups.length === 0 && <div className={styles.columnBigText}> In order to create a new Group
                    click on Add Group box above and type the Groups name. When done click anywhere on the screen or press the Enter key on your Keyboard</div>}
                </div>
              }
            { groupMembersLoading?<div className={styles.column}><div className={styles.columntext}>Group Members</div><div className={styles.loader}></div></div>:
              <div className={(selectedGroup>=0)?styles.column:styles.columninnactive}>
                <div className={styles.columntext}>{(selectedGroup>=0)?groups[selectedGroup].name:""} Group Members</div>
                <RenderGroupPeople />
                {selectedGroup>=0 && groupsMembers.length === 0 && <div className={styles.columnBigText}> In order to add Members on this group just click on the Members you want from the Available People on the organization list. </div>}
              </div>
            }
            { companyMembersLoading?<div className={styles.column}><div className={styles.columntext}>Available People in Organization</div><div className={styles.loader}></div></div>:
              <div className={(selectedGroup>=0)?styles.column:styles.columninnactive}>
                <div className={styles.columntext}>Available People in Organization</div>
                <Item name="search" mode="filter" filterPeople={setFilterPeople} active={(selectedGroup>=0)}/>
                <RenderPeople />
              </div>
            }
            { dateSettingsLoading?<div className={styles.column}><div className={styles.columntext}>Settings</div><div className={styles.loader}></div></div>:
              <div className={(selectedGroup>=0)?styles.groupsettings:styles.groupsettingsinnactive}>
                <div className={styles.columntext}>Settings</div>
                <form disabled={(selectedGroup>=0)}>
                  <Select options={options} value={interval} onChange={handleIntervalChange} className={(selectedGroup>=0)?itemStyles.item:itemStyles.iteminnactive} isSearchable={false} isDisabled={!(selectedGroup>=0)}/>
                  <DatePicker className={(selectedGroup>=0)?itemStyles.item:itemStyles.iteminnactive}
                    selected={startDate}
                    onChange={date => updateTime(date)}
                    disabled={!(selectedGroup>=0)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                  />
                </form>
                { interval.label === options[0].label && <div className={styles.columntext}>Daily, Monday to Friday.*<br/><br/></div>}
                { interval.label === options[1].label && <div className={styles.columntext}>Weekly, every Monday.<br/><br/></div>}
                { interval.label === options[2].label && <div className={styles.columntext}>Monthly, every first Monday.<br/><br/></div>}
                <div className={styles.columnBigText}>Timezone information is utilised from the information on your Slack profile.<br/><br/></div>
                { interval.label === options[0].label && <div className={styles.columnBigText}>* Our scheduler runs Daily at 00:00 UTC. in some rare cases messages might be off by one Day. Please Contact us on <br/> happinsights@gmail.com to find a solution<br/></div>}
              </div>
            }
          </div>
        </div>
      </div>
    );

}

let lol = () => {
  return (
    <div className={styles.angrySubscription}>
      <div className={styles.unhappyColumn}>
        <div className={styles.columntext}>Channel ID:</div>
        <input ref={unhappyChannelRef} className={(unhappyStatus!="channelError")?itemStyles.item:itemStyles.itemError} type="text" name="channelId" placeholder="Channel ID:" />
        <Popup trigger={ <HelpIcon svgClass={styles.svgIcon} fill={"#409040"} viewBox="0 0 24 24" width="2em" height="2em"></HelpIcon>
        } position="left center" modal>
          <div>
            <div>Step 1
              <ul>
                <li>Select the channel that you want HappInsights to send UnHappy updates.</li>
                <li>In the message field, type @HappInsights and select the HappInsights App. You can see a not in channel notice behind it.</li>
                <li>Send the message to the channel. You will receive a pop-up dialog to help you invite your bot user to this channel.</li>
              </ul>
              Alternatively
              <ul>
                <li>Right Click on the channel that you want HappInsights to send UnHappy updates.</li>
                <li>Select View Channel Details and then Integrations.</li>
                <li>On the Apps Section press add an App and select Happinsights.</li>
              </ul>
              Step 2
              <ul>
                <li>Right Click on the channel you selected on Step 1.</li>
                <li>Select View Channel Details.</li>
                <li>On the bottom part of the about section you can find the Channel ID.</li>
                <li>Copy the Channel ID. and paste it in the Channel ID field.</li>
              </ul>
              Step 3
              <ul>
                <li>Toggle the Switch to enabe the Unhappy Button.</li>
                <li>A welcome message will be sent to the selected Channel.</li>
                <li>Your organization members will have access to the Unhappy button in the HappInsights home tab.</li>
              </ul>

            </div>
          </div>
        </Popup>
      </div>
      <div className={styles.angrySubscriptionDescription}>
       Unhappy Button Subscription: It enables the Unhappy button locted in the HappInsights Home tab for the entire organization.
      When that button is pressed it triggers a message on a predefined Sleck Channel. The Unhappy Button Subscription Costs 0.25$ per person per month.
      If enabled it costs {(numberOfPeople * 0.10).toFixed(2)}$ per month for the {numberOfPeople} people in your organization.
      *minimum charge for 2 persons of total 0.50$ usd applies
      {unhappyError?<div className={styles.unhappyError}>{unhappyError}</div>:<div/>}
      </div>
      <div className={styles.angrySubscriptionSwitchColumn}>
        <div className={styles.angrySubscriptionSwitchTop}></div>
        <div className={styles.angrySubscriptionSwitch}>
          <Switch onChange={handleAngrySwitch} checked={unhappyState} />
        </div>
        <div></div>
      </div>
    </div>
  )
}

export default Settings;
