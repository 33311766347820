import * as React from "react";

function SvgAdd24Px(props) {
  return (
    <svg className={props.svgClass} height={props.height} viewBox={props.viewBox} width={props.width}>
      <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" fill={props.fill}/>
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
}

export default SvgAdd24Px;
