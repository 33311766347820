import React, { useContext } from "react";
import {
  Redirect,
  useLocation
} from "react-router-dom";
import {setSessionCookie, getSessionCookie} from './session.js';
import queryString from 'query-string'
import {SessionContext} from './session.js';
import styles from './css/tos.module.css';
import imgSettings from '../images/settings.png';
import imgSlack from '../images/slack.png';
import imgDashboard from '../images/dashboard.png';
import imgHappy from '../images/happinsights.png';

const Support = () => {
  const { session } = useContext(SessionContext);

  return (
    <div>

      <div className={styles.A}>
        <div className={styles.void}><br/></div>
        <div className={styles.row}>
          <div className={styles.column}>
            <h1 className={styles.title}>Support</h1>
            <img className={styles.img} src={imgHappy}/>
            <br/>
              <p className={styles.paragraph}>
            Email us: happinsights@gmail.com and info@happinsights.com<br/>
            Call us: +4571846567<br/>
            Discord: <a href="https://discord.gg/3tXeKsTnRG">Chat live on our Discord Channel</a> <br/></p>
            <br/>
            <br/>
          </div>
        </div>
      </div>
      <div className={styles.C}>
        <div className={styles.void}><br/></div>
        <div className={styles.row}>
          <div className={styles.column}>
            <p className={styles.paragraph}>
            email: info@happinsights.com<br/>
            Tel: +4571846567<br/>
            © 2022 HappInsights</p>
          </div>
          <div className={styles.column}>
            <p className={styles.paragraph}>
            Only Strictly necessary, Persistent Authentication cookies are stored.<br/>
            Furthermore, Stripe identification cookies are used, and only for our app to function properly.</p>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <a href="/">Home</a>
          </div>
          <div className={styles.column}>
            <a href="/tos">Terms Of Service</a>
          </div>
          <div className={styles.column}>
            <a href="/support">Support</a>
          </div>
          <div className={styles.column}>
            <a href="/privacy-policy">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
    );
}

export default Support
