import React, { useContext } from "react";
import {
  Redirect,
  useLocation
} from "react-router-dom";
import {setSessionCookie, getSessionCookie} from './session.js';
import queryString from 'query-string'
import {SessionContext} from './session.js';
import styles from './css/tos.module.css';
import imgSettings from '../images/settings.png';
import imgSlack from '../images/slack.png';
import imgDashboard from '../images/dashboard.png';
import imgHappy from '../images/happinsights.png';

const PrivacyPolicy = () => {
  const { session } = useContext(SessionContext);

  return (
    <div>

      <div className={styles.A}>
        <div className={styles.void}><br/></div>
        <div className={styles.row}>
          <div className={styles.column}>
            <h1 className={styles.title}>Privacy Policy</h1>
            <p className={styles.paragraph}>Last updated: April 18, 2022</p>
            <p className={styles.paragraph}>At HappInsights, accessible from https://www.happinsights.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by HappInsights and how we use it.</p>

            <p className={styles.paragraph}>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us. Our Privacy Policy was generated with the help of <a href="https://www.gdprprivacynotice.com/">GDPR Privacy Policy Generator from GDPRPrivacyNotice.com</a></p>

            <h2 className={styles.title}>General Data Protection Regulation (GDPR)</h2>
            <p className={styles.paragraph}>We are a Data Controller of your information.</p>

            <p className={styles.paragraph}>HappInsights legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Information we collect and the specific context in which we collect the information:</p>
            <ul>
                <li className={styles.paragraph}>HappInsights needs to perform a contract with you</li>
                <li className={styles.paragraph}>You have given HappInsights permission to do so</li>
                <li className={styles.paragraph}>Processing your personal information is in HappInsights legitimate interests</li>
                <li className={styles.paragraph}>HappInsights needs to comply with the law</li>
            </ul>

            <p className={styles.paragraph}>HappInsights will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.</p>

            <p className={styles.paragraph}>You have certain data protection rights. If you wish to be informed what Personal Information we hold about you and if you want it to be removed from our systems, please contact us by email: happinsights@gmail.com.</p>
            <p className={styles.paragraph}>In certain circumstances, you have the following data protection rights:</p>
            <ul>
                <li className={styles.paragraph}>The right to access, update or to delete the information we have on you.</li>
                <li className={styles.paragraph}>The right of rectification.</li>
                <li className={styles.paragraph}>The right to object.</li>
                <li className={styles.paragraph}>The right of restriction.</li>
                <li className={styles.paragraph}>The right to data portability</li>
                <li className={styles.paragraph}>The right to withdraw consent</li>
            </ul>

            <h2 className={styles.title}>Data Protection Rights Requests</h2>
            <p className={styles.paragraph}>If you wish to Delete, Access, Update, Rectify, Object, Transfer or Restrict your personal information, or even withdraw your consent you can do so at any time by contacting us by email on happinsights@gmail.com. HappInsights will promptly delete, give you or update your requested information.</p>

            <h2 className={styles.title}>Log Files</h2>

            <p className={styles.paragraph}>HappInsights follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>

            <h2 className={styles.title}>Cookies and Web Beacons</h2>

            <p className={styles.paragraph}>Like any other website, HappInsights uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>

            <p className={styles.paragraph}>For more general information on cookies, please read <a href="https://www.generateprivacypolicy.com/#cookies">"Cookies" article from the Privacy Policy Generator</a>.</p>



            <h2 className={styles.title}>Privacy Policies</h2>

            <p className={styles.paragraph}>Only Strictly necessary, Persistent Authentication cookies are stored and only for our app to function properly.</p>

            <p className={styles.paragraph}>Furthermore, Third Party Stripe identification cookies are used, and only for us to be able to accept subscriptions and payments through our partners at Stripe. Stripe cookies, JavaScript, or Web Beacons that are used in their respective payment system and links that appear on HappInsights, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to identify the machine of the the user and to update the content that you see on websites that you visit.</p>

            <p className={styles.paragraph}>Note that HappInsights has no access to or control over these cookies that are used by third-party.</p>

            <h2 className={styles.title}>Third Party Privacy Policies</h2>

            <p className={styles.paragraph}>HappInsights's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>

            <p className={styles.paragraph}>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>

            <h2 className={styles.title}>Children's Information</h2>

            <p className={styles.paragraph}>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>

            <p className={styles.paragraph}>HappInsights does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>

            <h2 className={styles.title}>Online Privacy Policy Only</h2>

            <p className={styles.paragraph}>Our Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in HappInsights. This policy is not applicable to any information collected offline or via channels other than this website.</p>

            <h2 className={styles.title}>Consent</h2>

            <p className={styles.paragraph}>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
            <h1 className={styles.title}>Contact Us</h1>
            <p className={styles.paragraph}>If you have any questions about this Privacy Policy, You can contact us:</p>
            <ul>
            <li className={styles.paragraph}>By email: happinsights@gmail.com</li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.C}>
        <div className={styles.void}><br/></div>
        <div className={styles.row}>
          <div className={styles.column}>
            <p className={styles.paragraph}>
            email: info@happinsights.com<br/>
            Tel: +4571846567<br/>
            © 2022 HappInsights</p>
          </div>
          <div className={styles.column}>
            <p className={styles.paragraph}>
            Only Strictly necessary, Persistent Authentication cookies are stored.<br/>
            Furthermore, Stripe identification cookies are used, and only for our app to function properly.</p>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <a href="/">Home</a>
          </div>
          <div className={styles.column}>
            <a href="/tos">Terms Of Service</a>
          </div>
          <div className={styles.column}>
            <a href="/support">Support</a>
          </div>
          <div className={styles.column}>
            <a href="/privacy-policy">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
    );
}

export default PrivacyPolicy
