import React from "react";

//https://github.com/gregberge/svgr
//npx @svgr/cli --icon --replace-attr-values "#063855=currentColor" icon.svg
function SvgHappInsights24Px(props) {
  return (
    <svg className={props.svgClass}  height={props.height} viewBox={props.viewBox} width={props.width}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <circle cx={15.5} cy={9.5} r={1.5} fill={props.fill}/>
      <circle cx={8.5} cy={9.5} r={1.5} fill={props.fill}/>
      <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-5-6c.78 2.34 2.72 4 5 4s4.22-1.66 5-4H7z" fill={props.fill}/>
    </svg>
  );
}

export default SvgHappInsights24Px;
