import React from "react";

function SvgShowChart24Px(props) {
  return (
    <svg className={props.svgClass} height={props.height} viewBox={props.viewBox} width={props.width}>
      <path d="M3.5 18.49l6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z" fill={props.fill}/>
      <path d="M0 0h24v24H0z" fill="none"/>
    </svg>
  );
}

export default SvgShowChart24Px;
