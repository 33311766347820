import * as React from "react";

function SvgEdit24Px(props) {
  return (
  <svg className={props.svgClass} height={props.height} viewBox={props.viewBox} width={props.width}>
    <path d="M11.95 18q.525 0 .888-.363.362-.362.362-.887t-.362-.887q-.363-.363-.888-.363t-.888.363q-.362.362-.362.887t.362.887q.363.363.888.363Zm-.9-3.85h1.85q0-.825.188-1.3.187-.475 1.062-1.3.65-.65 1.025-1.238.375-.587.375-1.412 0-1.4-1.025-2.15T12.1 6q-1.425 0-2.312.75-.888.75-1.238 1.8l1.65.65q.125-.45.563-.975Q11.2 7.7 12.1 7.7q.8 0 1.2.437.4.438.4.963 0 .5-.3.937-.3.438-.75.813-1.1.975-1.35 1.475-.25.5-.25 1.825ZM12 22q-2.05 0-3.875-.788-1.825-.787-3.187-2.137-1.363-1.35-2.15-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.15-3.175Q6.3 3.575 8.125 2.787 9.95 2 12 2q2.1 0 3.925.787 1.825.788 3.175 2.138 1.35 1.35 2.125 3.175Q22 9.925 22 12t-.775 3.9q-.775 1.825-2.125 3.175-1.35 1.35-3.175 2.137Q14.1 22 12 22Zm0-10Zm0 8q3.35 0 5.675-2.337Q20 15.325 20 12t-2.325-5.663Q15.35 4 12 4 8.725 4 6.362 6.337 4 8.675 4 12t2.362 5.663Q8.725 20 12 20Z" fill={props.fill}/>
  </svg>
)};

export default SvgEdit24Px;
