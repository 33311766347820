import React, { useContext, useState, useEffect, useCallback } from "react";
import {
  Redirect,
  useLocation
} from "react-router-dom";
import {SessionContext} from './session.js';
import styles from './css/subscribe.module.css';
import imgSettings from '../images/settings.png';
import imgSlack from '../images/slack.png';
import imgDashboard from '../images/dashboard.png';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import StripeManager from '../services/StripeManager.js';
import useHappyApi from './helpers/useHappyApi.js';
import PoweredByStripeIcon from './icons/powered-by-stripe.js';


const stripePromise = loadStripe(process.env.STRIPE_API_KEY);
const stripeManager = new StripeManager;

const Upgrade = () => {
  const { session } = useContext(SessionContext);
  const [customerState, , , refetchCustomer] = useHappyApi(`${process.env.STRIPE_ENDPOINT}/create-customer`);
  const [companiesState] = useHappyApi(`${process.env.API_ENDPOINT}/companies`);
  const [customerData, setCustomerData] = useState({});
  const [subscription, setSubscription] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [happinsightsSubscriptionQuantity, setHappinsightsSubscriptionQuantity] = useState(0);
  const [unhappySubscriptionQuantity, setUnhappySubscriptionQuantity] = useState(0);
  const [happinsightsSubscriptionAmount, setHappinsightsSubscriptionAmount] = useState(0);
  const [unhappySubscriptionAmount, setUnhappySubscriptionAmount] = useState(0);
  const [unhappySubscriptionId, setUnhappySubscriptionId] = useState();
  const [happinsightsSubscriptionId, setHappinsightsSubscriptionId] = useState();
  const [checked, setChecked] = React.useState(false);

  if (!session) {return(<Redirect to="/" />);}



// Ahhhh, okay. I see. In that case, you would just monitor for the event invoice.paid and check the payload to see if amount_paid=0

//useEffect(() => {

//  let priceCalculator = () => {
//    if (companiesState?.data) {
//      let total_user_count = (companiesState?.data?.companies[0]?.total_user_count>1)?companiesState?.data?.companies[0]?.total_user_count:2
//      let unhappy_users = companiesState?.data?.companies[0]?.unhappy_subscription_switch?total_user_count:0
//      return (companiesState?.data?.companies[0]?.subscription_current_users*0.5) + (unhappy_users*0.25)
//    } else { return 0 };
//  }



//}, [companiesState.data])

  const getAndSetSubscription = async (subscriptionId) => {
    let subscription = await stripeManager.retrieveSubscription(subscriptionId, session);
    console.log(subscription);
    setSubscription(subscription);
    setLoading(false);
  //  console.log("hello")
  //  console.log(subscription?.items?.data);
    if (subscription?.items && subscription?.items?.data?.length == 2){
      //console.log(subscription?.items?.data[0]?.price?.id);
      if (subscription?.items?.data[0]?.price?.id == process.env.STRIPE_PRICE_ID) {
        setHappinsightsSubscriptionQuantity(subscription?.items?.data[0]?.quantity)
        setHappinsightsSubscriptionAmount((subscription?.items?.data[0]?.quantity*subscription?.items?.data[0]?.price?.unit_amount)/100)
        setHappinsightsSubscriptionId(subscription?.items?.data[0]?.id)
      } else if (subscription?.items?.data[1]?.price?.id == process.env.STRIPE_PRICE_ID) {
        setHappinsightsSubscriptionQuantity(subscription?.items?.data[1]?.quantity)
        setHappinsightsSubscriptionAmount((subscription?.items?.data[1]?.quantity*subscription?.items?.data[1]?.price?.unit_amount)/100)
        setHappinsightsSubscriptionId(subscription?.items?.data[1]?.id)
      }
      if (subscription?.items?.data[0]?.price?.id == process.env.STRIPE_UNHAPPY_PRICE_ID) {
        setUnhappySubscriptionQuantity(subscription?.items?.data[0]?.quantity)
        setUnhappySubscriptionAmount((subscription?.items?.data[0]?.quantity*subscription?.items?.data[0]?.price?.unit_amount)/100)
        setUnhappySubscriptionId(subscription?.items?.data[0]?.id)
      } else if (subscription?.items?.data[1]?.price?.id == process.env.STRIPE_UNHAPPY_PRICE_ID) {
        setUnhappySubscriptionQuantity(subscription?.items?.data[1]?.quantity)
        setUnhappySubscriptionAmount((subscription?.items?.data[1]?.quantity*subscription?.items?.data[1]?.price?.unit_amount)/100)
        setUnhappySubscriptionId(subscription?.items?.data[1]?.id)
      }
  //    console.log(process.env.STRIPE_PRICE_ID);
//      console.log("lentgth 2");
} else if (subscription?.items && subscription?.items?.data?.length == 1){
      //console.log(subscription?.items?.data[0]?.price?.id);
      if (subscription?.items?.data[0]?.price?.id == process.env.STRIPE_PRICE_ID) {
        setHappinsightsSubscriptionQuantity(subscription?.items?.data[0]?.quantity)
        setHappinsightsSubscriptionAmount((subscription?.items?.data[0]?.quantity*subscription?.items?.data[0]?.price?.unit_amount)/100)
        setHappinsightsSubscriptionId(subscription?.items?.data[0]?.id)
      } else if (subscription?.items?.data[0]?.price?.id == process.env.STRIPE_UNHAPPY_PRICE_ID) {
        setUnhappySubscriptionQuantity(subscription?.items?.data[0]?.quantity)
        setUnhappySubscriptionAmount((subscription?.items?.data[0]?.quantity*subscription?.items?.data[0]?.price?.unit_amount)/100)
        setUnhappySubscriptionId(subscription?.items?.data[0]?.id)
      }
    }
    //console.log(subscription);
  }

  useEffect(() => {
    if (customerState.data && customerState.data.statusCode == 200){
      setCustomerData(JSON.parse(customerState.data.body));
      getAndSetSubscription(JSON.parse(customerState.data.body).subscription_id);

      //console.log(JSON.parse(customerState.data.body))
    } else if (customerState.data && customerState.data.statusCode != 200) {
      setLoading(false);
      setError(true);
      setErrorMsg(customerState.data?.body || '');
    }
  }, [customerState]);

  if (isLoading) {
    return <div className={styles.loader}></div>;
  }

  if (isError) {
    if (errorMsg != ''){
      return <div className={styles.error}>{errorMsg}</div>;
    }
    return <div className={styles.error}> There was an internal Error, Someone is working on this, please try again later</div>;
  }

  const updateAndRefetch = (subscription) => {
    setLoading(true);
    setSubscription(subscription);
    refetchCustomer();
    setLoading(false);
  }
  const cancelSubscription = async (subscriptionId, cancelation) => {
    let subscription = await stripeManager.cancelSubscription(subscriptionId, session, cancelation);
    setSubscription(subscription);
    refetchCustomer();
  }

//fix quantities here
  const updateSubscription = async (subscriptionId, quantity,unhappyQuantity) => {
    let subscription = await stripeManager.updateSubscription(subscriptionId, session, quantity, unhappyQuantity, happinsightsSubscriptionId, unhappySubscriptionId);
    setSubscription(subscription);
    refetchCustomer();
  }

  const handleCheckedChange = () => {
    setChecked(!checked);
  };


  return (
    <div className={styles.subscribe}>

        <div className={styles.void}><br/></div>
        {subscription?.pending_update?
        <PendingSubscription checked={checked} handleCheckedChange={handleCheckedChange} customerData={customerData} setSubscription={setSubscription} subscription={subscription} updateAndRefetch={updateAndRefetch} quantitiesAndAmounts={{happinsightsSubscriptionQuantity, unhappySubscriptionQuantity, happinsightsSubscriptionAmount, unhappySubscriptionAmount}} />
        :((customerData.subscription_name == 'Custom' || customerData.subscription_name == 'UnhappyButtonPlan' || customerData.subscription_name == 'XXL')?
        <Subscribed checked={checked} handleCheckedChange={handleCheckedChange} customerData={customerData} subscription={subscription} cancelSubscription={cancelSubscription} updateSubscription={updateSubscription} quantitiesAndAmounts={{happinsightsSubscriptionQuantity, unhappySubscriptionQuantity, happinsightsSubscriptionAmount, unhappySubscriptionAmount}}/>
        :<UnSubscribed checked={checked} handleCheckedChange={handleCheckedChange} customerData={customerData} subscription={subscription} setSubscription={setSubscription} updateAndRefetch={updateAndRefetch} quantitiesAndAmounts={{happinsightsSubscriptionQuantity, unhappySubscriptionQuantity, happinsightsSubscriptionAmount, unhappySubscriptionAmount}} />)}
    </div>
  );
};

const Subscribed = ({checked, handleCheckedChange, customerData, subscription, cancelSubscription, updateSubscription, quantitiesAndAmounts}) =>{
  const handleCancelSubscription = (ev) => {
    cancelSubscription(customerData?.subscription_id)
  }

  let unhappy_quantity = 0;
  if (customerData?.unhappy_subscription_switch) {
    unhappy_quantity = customerData?.unhappy_quantity;
  }
//  console.log(unhappy_quantity)
//  console.log(customerData)
//  console.log(customerData?.unhappy_quantity)
  let currentPrice = (customerData?.subscription_current_users*0.5) + (unhappy_quantity*0.25);
  let oldPrice = (customerData?.subscription_max_users*0.5) + (customerData?.unhappy_subscription_quantity*0.25);
  //console.log(subscription);
  return(
    <div>
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>Your Subscription</h1>
        <PoweredByStripeIcon className={styles.poweredByStripeIcon} width="8em"></PoweredByStripeIcon>
      </div>
      <div className={styles.title}> Happinsights Subscription current seats: {quantitiesAndAmounts.happinsightsSubscriptionQuantity}</div>
      <div className={styles.title}> Happinsights Subscription new seats: {customerData?.subscription_current_users}</div>
      <div className={styles.title}> Unhappy Button Subscription current seats: {quantitiesAndAmounts.unhappySubscriptionQuantity}</div>
      <div className={styles.title}> Unhappy Button Subscription new seats: {unhappy_quantity}</div>
      <div className={styles.title}> Happinsights Subscription current Price: {quantitiesAndAmounts.happinsightsSubscriptionAmount + "$ per month"}</div>
      <div className={styles.title}> Happinsights Subscription new Price: {(customerData?.subscription_current_users*0.5) + "$ per month"}</div>
      <div className={styles.title}> Unhappy Button Subscription current Price: {quantitiesAndAmounts.unhappySubscriptionAmount + "$ per month"}</div>
      <div className={styles.title}> Unhappy Button Subscription new Price: {(unhappy_quantity*0.25) + "$ per month"}</div>
      <div className={styles.title}> Total current Price: {(quantitiesAndAmounts.happinsightsSubscriptionAmount + quantitiesAndAmounts.unhappySubscriptionAmount) + "$ per month"}</div>
      <div className={styles.title}> Total new Price: {currentPrice + "$ per month"}</div>
      <SubscriptionDates subscription={subscription} />
      <div className={styles.subscriptionButtons}>
        <SubscriptionUpgradeButon subscription={subscription} updateSubscription={updateSubscription} customerData={customerData}/>
        <SubscriptionStatusButon subscription={subscription} cancelSubscription={cancelSubscription} customerData={customerData}/>
      </div>
    </div>
  );
}

const SubscriptionDates= ({subscription}) =>{
  if (subscription.cancel_at_period_end) {
    return(
      <div className={styles.title}> Subscription Ends: {subscription?.current_period_end?new Date(subscription?.current_period_end*1000).toLocaleString():" Loading"}</div>
    )
  } else if (subscription.status == "active") {
       return(
         <div className={styles.title}> Next Renewal: {subscription?.current_period_end?new Date(subscription?.current_period_end*1000).toLocaleString():" Loading"}</div>
       )
  } else {
    return(<div/>)
  }
}

const SubscriptionUpgradeButon= ({subscription, updateSubscription, customerData}) =>{
//  console.log(subscription?.items?.data[0].id)
  const handleUpdateSubscription = (ev) => {
    let unhappy_quantity = 0;
    if (customerData?.unhappy_subscription_switch) {
      unhappy_quantity = customerData?.unhappy_quantity;
    }
    updateSubscription(customerData?.subscription_id, customerData?.subscription_current_users, unhappy_quantity)
  }
//  console.log(customerData)
//  console.log(subscription)
  let unhappy_quantity = 0;
  if (customerData?.unhappy_subscription_switch) {
    unhappy_quantity = customerData?.unhappy_quantity;
  }
//  console.log(unhappy_quantity)
//  console.log(customerData)
//  console.log(customerData?.unhappy_quantity)
  let currentPrice = (customerData?.subscription_current_users*0.5) + (unhappy_quantity*0.25);
  let oldPrice = (customerData?.subscription_max_users*0.5) + (customerData?.unhappy_subscription_quantity*0.25);

  if (currentPrice > oldPrice) {
    return(
      <button className={styles.button} onClick={handleUpdateSubscription}>Upgrade Subscription ( {currentPrice}$ Per Month )</button>
    )
  } else if (currentPrice < oldPrice){
    return(
      <button className={styles.button} onClick={handleUpdateSubscription}>Downgrade Subscription ( {currentPrice}$ Per Month )</button>
    )
  } else {
    return(<div/>)
  }

// check for upgrades .... unhappy and happinsights


//  if (customerData?.unhappy_subscription == false) {
//    return(
//      <button className={styles.button} onClick={handleUpdateSubscription}>Unhappy Button Upgrade ( {customerData?.subscription_current_users*0.5}$ Per Month )</button>
//    )
//  }
//  if (customerData?.subscription_max_users > customerData?.subscription_current_users) {
//    return(
//      <button className={styles.button} onClick={handleUpdateSubscription}>Downgrade Subscription ( {customerData?.subscription_current_users*0.5}$ Per Month )</button>
//    )
//  } else if (customerData?.subscription_max_users < customerData?.subscription_current_users) {
//     return(
//       <button className={styles.button} onClick={handleUpdateSubscription}>Upgrade Subscription ( {customerData?.subscription_current_users*0.5}$ Per Month )</button>
//     )
//  } else {
//    return(<div/>)
//  }
}

const SubscriptionStatusButon= ({subscription, cancelSubscription, customerData}) =>{
  const handleCancelSubscription = useCallback((cancelation) => (ev) => {
    cancelSubscription(customerData?.subscription_id, cancelation)
  }, [], )
  if (subscription.status == "active") {
    if (subscription.cancel_at_period_end) {
      return(
        <button className={styles.button} onClick={handleCancelSubscription(false)}>Reactivate Subscription</button>
      )
    } else  {
         return(
           <button className={styles.button} onClick={handleCancelSubscription(true)}>Cancel Subscription</button>
         )
    }
  }else {
    return(<div/>)
  }
}

const UnSubscribed = ({checked, handleCheckedChange, customerData, setSubscription, updateAndRefetch, subscription, quantitiesAndAmounts}) =>{
  //console.log(subscription)
  // thelw to set subscription? afou exw hdh to subscription ktl
  // Ahhhh, okay. I see. In that case, you would just monitor for the event invoice.paid and check the payload to see if amount_paid=0
  //console.log(quantitiesAndAmounts);
  if (subscription.latest_invoice.paid == true && subscription.latest_invoice.amount_paid == 0)
  {
    //console.log("dd");
    return(
      <div>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>Upgrade now</h1>
          <PoweredByStripeIcon className={styles.poweredByStripeIcon} width="8em"></PoweredByStripeIcon>
        </div>
        <div className={styles.title}> Your subscription amount is too low. you will be charged on next cycle</div>
        <div className={styles.title}> Happinsights Subscription seats: {quantitiesAndAmounts.happinsightsSubscriptionQuantity}</div>
        <div className={styles.title}> Unhappy Button Subscription seats: {quantitiesAndAmounts.unhappySubscriptionQuantity}</div>
        <div className={styles.title}> Happinsights Subscription Price: {quantitiesAndAmounts.happinsightsSubscriptionAmount + "$ per month"}</div>
        <div className={styles.title}> Unhappy Button Subscription Price: {quantitiesAndAmounts.unhappySubscriptionAmount + "$ per month"}</div>
        <div className={styles.title}> Total Price: {(quantitiesAndAmounts.happinsightsSubscriptionAmount + quantitiesAndAmounts.unhappySubscriptionAmount) + "$ per month"}</div>
      </div>
    )
  }
//  console.log("unsubscribed");
//  console.log(subscription);
  const options = {
  // passing the client secret obtained from the server
  //      <div className={styles.title}> Happinsights Subscription current seats: {customerData?.subscription_current_users}</div>
    clientSecret: subscription?.latest_invoice?.payment_intent?.client_secret,
  };
  return(
    <div>
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>Upgrade now</h1>
        <PoweredByStripeIcon className={styles.poweredByStripeIcon} width="8em"></PoweredByStripeIcon>
      </div>
      <div className={styles.title}> Happinsights Subscription seats: {quantitiesAndAmounts.happinsightsSubscriptionQuantity}</div>
      <div className={styles.title}> Unhappy Button Subscription seats: {quantitiesAndAmounts.unhappySubscriptionQuantity}</div>
      <div className={styles.title}> Happinsights Subscription Price: {quantitiesAndAmounts.happinsightsSubscriptionAmount + "$ per month"}</div>
      <div className={styles.title}> Unhappy Button Subscription Price: {quantitiesAndAmounts.unhappySubscriptionAmount + "$ per month"}</div>
      <div className={styles.title}> Total Price: {(quantitiesAndAmounts.happinsightsSubscriptionAmount + quantitiesAndAmounts.unhappySubscriptionAmount) + "$ per month"}</div>
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm checked={checked} handleCheckedChange={handleCheckedChange} customerData={customerData} subscription={subscription} setSubscription={setSubscription} updateAndRefetch={updateAndRefetch} quantitiesAndAmounts={quantitiesAndAmounts}/>
      </Elements>
    </div>
  );
}

const PendingSubscription = ({checked, handleCheckedChange, customerData, setSubscription, subscription, updateAndRefetch, quantitiesAndAmounts}) =>{
//  console.log("unsubscribed");

  const options = {
  // passing the client secret obtained from the server
    clientSecret: subscription?.latest_invoice?.payment_intent?.client_secret,
  };
  //console.log(subscription)
  return(
    <div>
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>Upgrade now</h1>
        <PoweredByStripeIcon className={styles.poweredByStripeIcon} width="8em"></PoweredByStripeIcon>
      </div>
      <div className={styles.title}> Happinsights Subscription seats: {quantitiesAndAmounts.happinsightsSubscriptionQuantity}</div>
      <div className={styles.title}> Unhappy Button Subscription seats: {quantitiesAndAmounts.unhappySubscriptionQuantity}</div>
      <div className={styles.title}> Happinsights Subscription Price: {quantitiesAndAmounts.happinsightsSubscriptionAmount + "$ per month"}</div>
      <div className={styles.title}> Unhappy Button Subscription Price: {quantitiesAndAmounts.unhappySubscriptionAmount + "$ per month"}</div>
      <div className={styles.title}> Total Price: {(quantitiesAndAmounts.happinsightsSubscriptionAmount + quantitiesAndAmounts.unhappySubscriptionAmount) + "$ per month"}</div>
      <Elements stripe={stripePromise} options={options}>
        <NewPaymentForm customerData={customerData} setSubscription={setSubscription} subscription={subscription} updateAndRefetch={updateAndRefetch}/>
      </Elements>
    </div>
  );
}

const CheckoutForm = ({checked, handleCheckedChange, customerData, subscription, setSubscription, updateAndRefetch, quantitiesAndAmounts}) => {
  // Include these hooks:
  const { session } = useContext(SessionContext);

  const stripe = useStripe();
  const elements = useElements();
  const [nameInput, setNameInput] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [retry, setRetry] = useState(!!localStorage.getItem('invoice_retry'));

//  const [subscriptionState,, createSubscription] = useHappyApi(`${process.env.STRIPE_ENDPOINT}/create-subscription`);
  const handleSubmitPayment = async () => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }
    try {
      //const { error, paymentMethod } = await stripe.createPaymentMethod({
    //    type: 'card',
    //    card: elements.getElement(PaymentElement),
    //  });
      const { error: errorAction, paymentIntent } = await stripe.confirmPayment({elements,redirect: 'if_required'});
      if (errorAction && errorAction?.payment_intent?.status != "succeeded") {
        setLoading(false);
        setError(errorAction?.message || 'Something is not right...');
        throw Error(errorAction?.message || 'Something is not right...');
      }
      let paymentIntentId = paymentIntent?.id;
      if (errorAction?.payment_intent?.status == "succeeded") {
        paymentIntentId = errorAction?.payment_intent?.id;
      }
  //    console.log(paymentIntent)
  //    console.log(errorAction?.payment_intent);

      const confirmedSubscription = await stripeManager.checkSubscription(paymentIntentId, subscription.id, customerData.stripe_unique_id, session);
      //console.log(confirmedSubscription);
      if (confirmedSubscription.code=="card_declined") {
        setLoading(false);
        setError("Your card has been Declined. Please try again or add funds to your card");
        throw Error('Could not process payment.');
      }
      if (confirmedSubscription.status !== 'active') {
        setLoading(false);
        throw Error('Could not process payment.');
      }
      if (confirmedSubscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
        setRetry(true);
        setLoading(false);
        localStorage.setItem('latest_invoice_id', subscription.latest_invoice.id);
        throw Error('Your card was declined. Please try again or with another card');
      }
      setLoading(false);
      updateAndRefetch(confirmedSubscription);
    } catch (error) {
      console.error(error);
      // Let the user know that something went wrong here...
    }
  };
  const handleRetryPayment = async () => {
    // Not implemented yet
  };

  const handleServerResponse = async (subscription, customerID, paymentID,session) => {
    let response = subscription.latest_invoice.payment_intent
    if (response.error) {
      return subscription;
      // Show error from server on payment form
    } else if (response.status === "requires_action") {
      // Use Stripe.js to handle the required card action
      const { error: errorAction, paymentIntent } = await stripe.confirmCardPayment(response.client_secret);
      if (errorAction) {
        // Show error from Stripe.js in payment form
        return subscription;

      } else {
        // The card action has been handled
        // The PaymentIntent can be confirmed again on the server
        const confirmedSubscription = await stripeManager.checkSubscription(paymentID, subscription.id, session);
        //paymentMethodID, subscription.id, hasPaidPlan, decoded.user_id, decoded.company_id
        //console.log(confirmedSubscription)
        //handleServerResponse(subscription);
        return confirmedSubscription;
      }
    } else {
      // Show success message
      return subscription;
    }
  }


  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (this.props.stripe) {
      this.props.stripe
        .createToken()
        .then((payload) => console.log('[token]', payload));
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };
  const buttonAction = retry ? handleRetryPayment : handleSubmitPayment;
  return (
    <div className={styles.cardHolder}>

        <label className={styles.label}>
          <PaymentElement className={styles.StripeElement}/>
        </label>
        {(error!='')?<div className={styles.error}>{error}</div>:<div/>}
        {isLoading?
          <div className={styles.loader}></div>:
          <div>
            <div className={styles.checkbox}>
              <Checkbox
                label="Accept"
                value={checked}
                onChange={handleCheckedChange}
              />
            </div>
            <button disabled={!checked} className={checked?styles.button:styles.disabledButton} onClick={handleSubmitPayment}>Subscribe ( {(quantitiesAndAmounts.happinsightsSubscriptionAmount + quantitiesAndAmounts.unhappySubscriptionAmount) + "$ per month "})</button>
          </div>}
    </div>
  );
};

const Checkbox = ({ label, value, onChange }) => {
  return (
    <label>
      <input type="checkbox" checked={value} onChange={onChange} />
        I accept the <a href="https://www.happinsights.com/tos" target="_blank">Terms Of Service</a> And i Agree to use my email registered with my Slack account in order to create a subscription with Stripe and recieve invoices.

    </label>
  );
};

const NewPaymentForm = ({customerData, setSubscription, subscription, updateAndRefetch}) => {
  // Include these hooks:
  const { session } = useContext(SessionContext);

  const stripe = useStripe();
  const elements = useElements();
  const [nameInput, setNameInput] = useState('');
  const [retry, setRetry] = useState(!!localStorage.getItem('invoice_retry'));
  const [error, setError] = useState('');
  const [charge, setCharge] = useState((subscription.latest_invoice.amount_due >0)?
  "you will be charged "  +subscription.latest_invoice.amount_due/100 +
          "$ for the remaining of this month and "+ customerData?.subscription_current_users*0.5+
          "$ per month afterwords":'');

  const [isLoading, setLoading] = useState(false);

//  const [subscriptionState,, createSubscription] = useHappyApi(`${process.env.STRIPE_ENDPOINT}/create-subscription`);
  const handleSubmitPayment = async () => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }
    try {

      const { error: errorAction, paymentIntent } = await stripe.confirmPayment({elements,redirect: 'if_required'});
      if (errorAction) {
        setLoading(false);
        setError(errorAction?.message || 'Something is not right...');
        throw Error(errorAction?.message || 'Something is not right...');
      }

      const confirmedSubscription = await stripeManager.checkSubscription(paymentIntent.id, subscription.id, customerData.stripe_unique_id, session);
    //  console.log(confirmedSubscription);
      if (confirmedSubscription.code=="card_declined") {
        setLoading(false);
        setError("Your card has been Declined. Please try again or add funds to your card");
        throw Error('Could not process payment.');
      }
      if (confirmedSubscription.status !== 'active') {
        setLoading(false);
        throw Error('Could not process payment.');
      }
      if (confirmedSubscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
        setRetry(true);
        setLoading(false);
        localStorage.setItem('latest_invoice_id', subscription.latest_invoice.id);
        throw Error('Your card was declined. Please try again or with another card');
      }
      setLoading(false);
      updateAndRefetch(confirmedSubscription);

    } catch (error) {
      console.error(error);
      // Let the user know that something went wrong here...
    }
  };
  const handleRetryPayment = async () => {
    // Not implemented yet
  };

  const handleServerResponse = async (response, customerID, paymentID,session, subscriptionId) => {
    if (response.error) {
      // Show error from server on payment form
    } else if (response.status === "requires_action") {
      // Use Stripe.js to handle the required card action
      const { error: errorAction, paymentIntent } = await stripe.confirmCardPayment(response.client_secret);
      if (errorAction) {
        // Show error from Stripe.js in payment form

      } else {
        // The card action has been handled
        // The PaymentIntent can be confirmed again on the server
        const confirmedSubscription = await stripeManager.checkSubscription(paymentID, subscriptionId, session);
        //paymentMethodID, subscription.id, hasPaidPlan, decoded.user_id, decoded.company_id
        //console.log(confirmedSubscription)
        //handleServerResponse(subscription);
        return confirmedSubscription
      }
    } else {
      // Show success message
      return response
    }
  }


  const buttonAction = retry ? handleRetryPayment : handleSubmitPayment;
  return (
    <div className={styles.cardHolder}>

        <label className={styles.label}>
          <PaymentElement className={styles.StripeElement}/>
        </label>
        {(error!='')?<div className={styles.error}>{error}</div>:<div/>}
        {(charge!='')?<div className={styles.charge}>{charge}</div>:<div/>}
        {isLoading?
          <div className={styles.loader}></div>:
          <button className={styles.button} onClick={handleSubmitPayment}>Upgrade ( {customerData?.subscription_current_users*0.5 + "$ per month "})</button>}
    </div>
  );
};

export default Upgrade;
