import React from "react";

function SvgHome24Px(props) {
  return (
    <svg className={props.svgClass} height={props.height} viewBox={props.viewBox} width={props.width}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" fill={props.fill}/>
      <path d="M0 0h24v24H0z" fill="none"/>
    </svg>
  );
}

export default SvgHome24Px;
