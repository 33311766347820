import React, { useContext, useState, useEffect } from "react";
import styles from './css/navbar.module.css';
import HappInsights from './icons/happinsights-icon.js';
import { SessionContext, getSessionCookie, removeSessionCookie } from './session.js';
import useHappyApi from './helpers/useHappyApi.js';
import RefContext from '../context/refContext';
import ky from 'ky';

const NavBar = () => {

  const {session, updateSession} = useContext(SessionContext);
  const [companiesState, , , reFetchCompanies] = useHappyApi(`${process.env.API_ENDPOINT}/companies`);
  const [daysLeft, setDaysLeft] = useState(0);
  const [slackState, setSlackState] = useState('');
  // Function to collect data
  const getSlackStateData = async () => {
    const response = await ky.get(`${process.env.STRIPE_ENDPOINT}/get-state`)
    .then((response) => response.json());

    setSlackState(response.body);
  };

  useEffect(() => {
    getSlackStateData();
  }, []);
  const { add } = useContext(RefContext)
  useEffect(() => {
    add('navbarReFetchCompanies', reFetchCompanies);
  }, []);

  let signInToSlackUrl = "https://slack.com/openid/connect/authorize?response_type=code&scope=openid,email,profile&client_id=738337750161.747056655510&state=" + slackState +"&redirect_uri=https://dev.happinsights.com/dev/auth/slackauth"
  let signInToSlackUrlLocal = "https://slack.com/openid/connect/authorize?response_type=code&scope=openid,email,profile&client_id=738337750161.747056655510&state=" + slackState + "&redirect_uri=https://happinsights.loca.lt/auth/slackauth"
  //let addToSlackUrlLocal = "https://slack.com/oauth/v2/authorize?client_id=738337750161.747056655510&state=" + slackState + "&scope=users%3Aread%2Cchat%3Awrite&user_scope=openid%2Cprofile%2Cemail&redirect_uri=https%3A%2F%2Fdev.happinsights.com%2Fdev%2Fauth%2Fslackauth";


  useEffect(() => {
  //  console.log(companiesState)
    if (companiesState?.data?.companies[0].subscription_name && companiesState?.data?.companies[0].subscription_name != "Custom") {
      const date1 = new Date();
      const date2 = new Date(companiesState?.data?.companies[0].subscription_start_date);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    //  console.log(date1);
    //  console.log(date2);
    //  console.log(diffDays)
    //  console.log(companiesState?.data?.companies[0].subscription_start_date)
      if (diffDays > 15) {
        setDaysLeft(0);
      } else { setDaysLeft(15-diffDays); }
    }
  }, [companiesState])

  const LogoutHandler = () => {
    removeSessionCookie();
    updateSession();
  }
  const UserLogStatus = () => {
    if (session) {
      return(<button className={ styles.navbar_logout_button } onClick={LogoutHandler}>
              Log Out
             </button>);
    } else {
      if(process.env.NODE_ENV === "production"){
        return(<a href={signInToSlackUrl}>
             <img alt="Sign in with Slack" height="40" width="172" src="https://platform.slack-edge.com/img/sign_in_with_slack.png" srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x" /></a>);
      } else if(process.env.NODE_ENV === "local"){
        return(<a href={signInToSlackUrlLocal}>
             <img alt="Sign in with Slack" height="40" width="172" src="https://platform.slack-edge.com/img/sign_in_with_slack.png" srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x" /></a>);
      }
    }
  }

  let priceCalculator = () => {
    if (companiesState?.data) {
      let total_user_count = (companiesState?.data?.companies[0]?.total_user_count>1)?companiesState?.data?.companies[0]?.total_user_count:2
      let unhappy_users = companiesState?.data?.companies[0]?.unhappy_subscription_switch?total_user_count:0
      return (companiesState?.data?.companies[0]?.subscription_current_users*0.5) + (unhappy_users*0.25)
    } else { return 0 };
  }

  let pricingRender = () => {
    if (session && priceCalculator() > 0) {
      return (
        <div>
          Pricing: {priceCalculator()}$ per Month
        </div>
      )
    } else {
      return(<div></div>)
    }
  }

  return (
    <div className={[styles.navbar, styles.fixed_top].join(" ")}>
      <div className={styles.navbar_inner} >
        <div className={styles.navbar_items} >
          <div className={styles.navbar_logo} >
            <h1 className={styles.title} >
              <div className={[styles.navbar_icon, styles.navbar_icon_baseline].join(" ")}>
                <HappInsights fill="#409040" viewBox="0 0 24 24" width="8vh" height="8vh"/>
              </div>
              <a className={styles.link} href="https://www.happinsights.com">HappInsights</a>
            </h1>
          </div>
        </div>
        <div className={styles.freeTrial}>
          {(session && companiesState?.data?.companies[0].subscription_name && companiesState?.data?.companies[0].subscription_name == "FREE")?"Free Trial ("+ daysLeft+"  days left)":""}
        </div>
        <div>
          {pricingRender()}
        </div>
        <div className={[styles.navbar_items, styles.navbar_items_right].join(" ")}>
          <div className={styles.navbar_user_login}>
            <UserLogStatus />
          </div>
        </div>
      </div>
    </div>
    );
}


export default NavBar;
