import React, { useContext, useState, useEffect } from "react";
import {
  Redirect,
  useLocation
} from "react-router-dom";
import {setSessionCookie, getSessionCookie} from './session.js';
import queryString from 'query-string'
import {SessionContext} from './session.js';
import styles from './css/home.module.css';
import imgSettings from '../images/settings.png';
import imgSlack from '../images/slack.png';
import imgDashboard from '../images/dashboard.png';
import imgUnhappyButton from '../images/unhappybutton.png';
import imgUnhappyMessage from '../images/unhappymsg.png';
import ky from 'ky';


const Home = () => {
  const { session } = useContext(SessionContext);

  //const getState = ky.get(`${process.env.STRIPE_ENDPOINT}/get-state`).then(res => res.json())
  //    .then(json => setSlackState({ data: json }));
  //useEffect(() => {
  //  console.log("got")
  //  console.log(slackState)
  //}, [slackState]);
  //console.log(getState)
  let error = '';

  if (queryString.parse(useLocation().search).token)
  {
    console.log('setting cookie');
    setSessionCookie(queryString.parse(useLocation().search).token)
    return(<Redirect to="/settings" />)
  }
  if (queryString.parse(useLocation().search).error == "not-installed") {
    error = 'It seems you have not added this app on your organization. Please do so by pressing the button Add to slack!';
  }

  if (session) {return(<Redirect to="/settings" />);}
  return (
    <div>
      <div className={styles.A}>
        <div className={styles.void}><br/></div>
        <div className={styles.row}>
          <div className={styles.column}>
            <h1 className={styles.title}>Happiness Insights for your organization</h1>
            <div><p className={styles.paragraph}>A Happy employee is often more productive, but measuring daily happiness can be challenging.</p><br/>
              <p className={styles.paragraph}>HappInsights simplifies the process by providing a user-friendly app for Slack, which sends out anonymous surveys to selected employees to gauge their daily, weekly or monthly happiness levels.</p><br/>
              <p className={styles.paragraph}>In addition, it offers an "Unhappy Button" that allows all team members to discreetly notify management of any unhappiness via a predefined channel.</p><br/>
            </div>
          </div>
          <div className={styles.column}>
            <img className={styles.img} src={imgSlack}/>
          </div>
        </div>
      </div>
      <div className={styles.B}>
        <div className={styles.void}><br/><br/></div>
        <div className={styles.row}>
          <div className={styles.column}>
            <img className={styles.img} src={imgSettings}/>
          </div>
          <div className={styles.column}>
            <p className={styles.paragraph}>To begin using the happiness survey, you simply need to organize your employees into groups. These groups can be created on the Settings page by clicking the "Add Group" button.
            Once you have created at least one group, you can select it and begin adding employees from the "Available People in your organization" column.</p><br/>
            <p className={styles.paragraph}>The next step is to set the survey interval - whether it be daily, weekly, or monthly - and the time at which the happiness survey will be sent.
            Our app will then schedule and deliver the survey messages according to your specified interval and time.</p><br/>
            <p className={styles.paragraph}>Finally, the app will summarize the individual happiness answers and display the aggregated metrics on the organization's happiness dashboard, grouped by the respective teams.</p><br/>
          </div>
        </div>
      </div>
      <div className={styles.A}>
        <div className={styles.void}><br/><br/></div>
        <div className={styles.row}>
          <div className={styles.column}>
            <p className={styles.paragraph}>To utilize the "Unhappy Button" feature, you simply need to select a channel, invite the HappInsights bot to that channel, enter the channel ID in the "Unhappy Settings" field located on the Settings page, and activate the toggle switch.</p><br/>
          </div>
          <div className={styles.column}>
            <img className={styles.img} src={imgUnhappyButton}/>
          </div>
        </div>
      </div>
      <div className={styles.B}>
        <div className={styles.void}><br/><br/></div>
        <div className={styles.row}>
          <div className={styles.column}>
            <img className={styles.img} src={imgDashboard}/>
          </div>
          <div className={styles.column}>
            <h1 className={styles.title}>Pricing</h1>
            <p className={styles.paragraph}>Free Trial 14 Days - No Credit Card Required!!!</p>
            <p className={styles.paragraph}>You can start your subscription at any time.</p><br/>
            <p className={styles.paragraph}>2023 Launch Offer:</p>
            <p className={styles.paragraph}><b>0.5$</b> per <b>Person</b> per <b>Month</b> for the Happiness Survey</p>
            <p className={styles.paragraph}><b>0.25$</b> per <b>Person</b> per <b>Month</b> for the Unhappy Button</p><br/>
          </div>
        </div>
      </div>
      <div className={styles.A}>
        <div className={styles.void}><br/><br/></div>
        <div className={styles.row}>
          <div className={styles.column}>
            <h1 className={styles.title}>Installation</h1>
            <p className={styles.paragraph}></p><br/>
            <p className={styles.paragraph}>To begin the installation process, sign in with Slack by clicking the button on the top right corner. Once you have signed in, you will be prompted to press the "Add to Slack" button to complete the installation.</p><br/>
          </div>
          <div className={styles.column}>
            <img className={styles.img} src={imgUnhappyMessage}/>
          </div>
        </div>
      </div>
      <div className={styles.C}>
        <div className={styles.void}><br/></div>
        <div className={styles.row}>
          <div className={styles.column}>
            <p className={styles.paragraph}>
            email: info@happinsights.com<br/>
            Tel: +4571846567<br/>
            © 2022 HappInsights</p>
          </div>
          <div className={styles.column}>
            <p className={styles.paragraph}>
            Only Strictly necessary, Persistent Authentication cookies are stored.
            Furthermore, Stripe identification cookies are used, and only for our app to function properly.</p>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <a href="/">Home</a>
          </div>
          <div className={styles.column}>
            <a href="/tos">Terms Of Service</a>
          </div>
          <div className={styles.column}>
            <a href="/support">Support</a>
          </div>
          <div className={styles.column}>
            <a href="/privacy-policy">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
    );
}

export default Home
