import React, { useContext, useState } from "react";
import {SessionContext} from './session.js';
import styles from './css/requestAccess.module.css';
import useHappyApi from './helpers/useHappyApi.js';
import {
  Redirect
} from "react-router-dom";


// css to text exei aspro xrwma
const RequestAccess = () => {
  const { session } = useContext(SessionContext);
  const [ btnVisibility, setBtnVisibility] = useState(true)
  const [requestAccessState, setRequestAccessUrl] = useHappyApi();
  const requestAccess = () => {
    setRequestAccessUrl(`${process.env.API_ENDPOINT}/company/access`)
    setBtnVisibility(false);
  }
  const requestAccessMessage = () => {
    if (btnVisibility) {
      return (
          <div>
            <p  className={ styles.paragraph}>In order to use this app for this organization you need to request access.</p>
            <p className={ styles.paragraph}>Press the button "Request Access Now" below this text to do so.</p>
            <p className={ styles.paragraph}>An automated Slack message will be sent to your organisation administrator requesting access.</p>
            <p className={ styles.paragraph}><button className={ styles.request_access_button } onClick={requestAccess}>Request Access Now</button></p>
          </div>
        )
    } else {
      return (
        <div>
          <p  className={ styles.paragraph}>We have sent a slack message to your Administrator requesting Access</p>
          <p  className={ styles.paragraph}>We will message you on slack</p>
        </div>
      )
    }
  }
  if (!session) {return(<Redirect to="/" />);}
  return (
    <div className={styles.request_access}>
      <div className={styles.void}><br/></div>
      {requestAccessMessage()}
    </div>
  );
}

export default RequestAccess
