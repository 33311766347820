import React, { useContext } from "react";
import {
  Redirect,
  useLocation
} from "react-router-dom";
import {setSessionCookie, getSessionCookie} from './session.js';
import queryString from 'query-string'
import {SessionContext} from './session.js';
import styles from './css/error.module.css';

const Error = () => {
  const { session } = useContext(SessionContext);
  let error_msg = queryString.parse(useLocation().search).error;
  if (error_msg)
  {
    if (error_msg == 401){
      return(<div>
                <p  className={ styles.paragraph}>That organization you are trying to add allready exists</p>
                <p  className={ styles.paragraph}>try to sign in instead using the button on the top</p>
             </div>)
    }else if (error_msg == 400){
      return(<div>
                <p  className={ styles.paragraph}>Bad Input Error</p>
                <p  className={ styles.paragraph}>Something went wrong during installation</p>
             </div>)
   }
  }
  if (session) {return(<Redirect to="/settings" />);}
  return(<div>
            <p  className={ styles.paragraph}>unknown error</p>
         </div>)
}

export default Error
